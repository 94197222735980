
import { Component,ElementRef } from '@angular/core';
import { CommonModule, CurrencyPipe} from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    name = 'Angular';
    formattedAmount;
    amount;

    constructor(private currencyPipe : CurrencyPipe) { }
    transformAmount(element){
        this.formattedAmount = this.currencyPipe.transform(this.formattedAmount, '$');
  
        element.target.value = this.formattedAmount;
    }
}
