import { Component, OnInit, TemplateRef } from '@angular/core';

import { PageService } from '../../pages.service';
import { PageData } from '../../pages.model';

import { CONSTANTS } from '../../shared/calculations/calc-constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    pageData: PageData;
    constants = CONSTANTS;

    bsModalRef: BsModalRef;

    /**
     * Creates an instance of FooterComponent.
     *
     * @memberOf FooterComponent
     */
    constructor(private pageService: PageService,
        private modalService: BsModalService) { }

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf FooterComponent
     */
    ngOnInit() {
        this.pageData = this.pageService.pageData;
    }

    showSIPC(template: TemplateRef<any>) {
        this.bsModalRef = this.modalService.show(template);
    }

    hideSIPC() {
        this.bsModalRef.hide();
    }

    goToSIPC() {
        this.hideSIPC();
        window.open('https://www.sipc.org');
    }

}
