<div class="page-footer">

    <!-- <div class="col-sm-12">
        <app-navigation></app-navigation>
    </div>  -->

    <div class="row" *ngIf="pageData.showDisclosures">
        <div class="col-sm-12">
            <div class="footer-disclosures">

                <!-- <p>
                    Calculators and related tools are provided by DST Systems, Inc., an SS&C company, a party unaffiliated with your firm's service provider, National Financial Services LLC or Fidelity Brokerage Services LLC (“Fidelity”), or your firm. Neither Fidelity nor your firm has been
                    involved in the preparation or the content supplied by SS&C and does not guarantee or assume any responsibility for its content. The retirement planning, estate planning, and tax information contained herein is general in nature, is provided for informational and educational
                    purposes only, and should not be considered legal or tax advice.
                </p>
                <p>
                    Fidelity is not undertaking to provide impartial investment advice, or to give advice in a fiduciary capacity, in connection with any investment or transaction described herein. Fiduciaries are solely responsible for exercising independent judgment in evaluating any
                    transaction(s) and are assumed to be capable of evaluating investment risks independently, both in general and with regard to particular transactions and investment strategies. Fidelity has a financial interest in any transaction(s) that fiduciaries, and if applicable, their
                    clients, may enter into involving Fidelity’s products or services. Always consult an attorney or tax professional regarding your client’s specific legal or tax situation. By using these tools you acknowledge that it is your obligation to comply with any applicable legal and
                    regulatory requirements when providing the reports to your clients. You should consult with your firm’s legal or compliance personnel regarding the use of these tools and reports.
                </p>
                <p>
                    Fidelity Institutional<sup>SM</sup> provides clearing, custody, or other brokerage services through National Financial Services LLC or Fidelity Brokerage Services LLC, Members NYSE, SIPC.
                </p> -->

                <p>
                    Calculators and related tools are provided by DST Systems, Inc., an SS&C company, a 
                    party unaffiliated with National Financial Services LLC, or Fidelity Brokerage 
                    Services LLC (“Fidelity”), or your firm. Except for Fidelity providing the Retiree 
                    healthcare numbers to be used in the calculator, neither Fidelity nor your firm has 
                    been involved in the preparation or the content supplied by SS&C and does not guarantee 
                    or assume any responsibility for its content. The information contained herein is 
                    general in nature, is provided for informational and educational purposes only, and 
                    should not be considered legal or tax advice. The information is not tailored to the 
                    investment needs of any specific investor.
                </p>
                <p>
                    1093860.2.0
                </p>

                <!-- <div class="row">
                    <div class="col-md-6">
                        <table class="table table-condensed table-bordered col-sm-4">
                            <tbody>
                                <tr>
                                    <td>Are Not FDIC Insured</td>
                                    <td>Are Not Bank Guaranteed</td>
                                    <td>May Lose Value</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> -->

                <!-- <p>
                    &copy; Copyright {{constants.currentYear}} Fidelity Brokerage Services LLC. All Rights Reserved.
                    <span class=""></span>
                </p> -->
            </div>
        </div>
    </div>
</div>



<ng-template #modalSIPC>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Fidelity</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideSIPC()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-center">
            <p class="important font-bold mb-0">Important Notice</p>
            <p class="subtitle">You're continuing to another website</p>
        </div>
        <p>
            You're continuing to another website that Fidelity doesn't own or operate.
            It's owner is solely responsible for the website's content offerings and level of security,
            so please refer to the website's posted privacy policy and terms of use.
        </p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-blue" (click)="hideSIPC()">Cancel</button>
        <button class="btn btn-blue" (click)="goToSIPC()">Continue</button>
    </div>
</ng-template>