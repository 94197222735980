<div class="row">
    <div class="col-sm-12">
       
        <div class="row summary-value-container mt-4">
            <div class="col-sm-8 no-pad-right-col">
                <div *ngIf="summaryItem.modifier == ''" class="summary-value">
                    {{summaryItem.value}}
                </div>
                <div *ngIf="summaryItem.modifier == 'currency'" class="summary-value">
                    {{summaryItem.value | currency:'USD':'symbol':'1.0-0'}}
                </div>
                <div *ngIf="summaryItem.modifier == 'percent'" class="summary-value">
                    {{summaryItem.value}}%
                </div>
            </div>
            <div *ngIf="summaryItem.link != ''" class="col-sm-2 no-pad-left-col summary-link">
                <a routerLink="/{{summaryItem.link}}"><img tooltip="click to modify" placement="top" src="assets/img/icon-arrow-back.png" alt="icon-arrow-back"></a>
            </div>
        </div>
        <div class="summary-label">
            {{summaryItem.title}}
        </div>
    </div>
</div>