import { Component, OnInit } from '@angular/core';

import { PageService } from '../pages.service';
import { PageData } from '../pages.model';

import { SessionService } from '../../shared/session/session.service';
import { CalculationsService } from '../shared/calculations/calculations.service';
import { CONSTANTS } from '../shared/calculations/calc-constants';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    pageData: PageData;

    imageNum: number;

    /**
     * Creates an instance of HomeComponent.
     * @param {PageService} pageService
     *
     * @memberOf HomeComponent
     */
    constructor(private pageService: PageService,
                private sessionService: SessionService) { }

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf HomeComponent
     */
    ngOnInit() {
        this.sessionService.resetSession();
        this.initPageData();
        this.imageNum = this.randomIntFromInterval(1, 3);
    }

    /**
     * Inits page specific data and sends this data to the page service.
     *
     * @memberOf HomeComponent
     */
    initPageData() {
        this.pageData = {
            currentPage: 'home',
            nextPage: 'step1',
            prevPage: '',
            progress: 0,
            stepNum: 0,
            firstPage: true,
            lastPage: true,
            showProgress: false,
            stepTitle: '',
            showFootnote: true,
            showNavigation: false,
            stepIcon: ''
        };
        this.pageService.initPage(this.pageData);
    }

    /**
     * Get constants for use in HTML.
     *
     * @readonly
     *
     * @memberOf HomeComponent
     */
    get constants() {
        return CONSTANTS;
    }

    /**
     * Generates a random integer between two values.
     *
     * @param {number} min
     * @param {number} max
     * @returns {number} random int
     *
     * @memberOf HomeComponent
     */
    randomIntFromInterval(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

}
