<title>Step 2</title>
<div id="step2" class="row justify-content-center">
    <form novalidate [formGroup]="formStep2">
    <div class="col-sm-12">
        <div class="callout-container-shaded pl-5 pr-5">
            <div class="row padded-row mt-1">

                <div class="col-sm-3 col-12 no-padding">
                    <span class="green-title-lg">Annual health care costs</span>
                </div> 
                <div class="offset-sm-2 col-sm-7 col-12 no-padding ">
                    <div class="intro-input-label step2-input">If you know your total annual out-of-pocket health care costs, enter the amount below. <img triggers="click" placement="auto top" [outsideClick]="true" [popover]="OOPTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png"></div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-5">
                            <div class="form-group">
   <!-- (blur)="utilService.fmt($event)"  -->
                                <div class="form-group">
                                  
                                    <input 
                                        aria-label="Total annual out-of-pocket health care costs"
                                        type='text' 
                                        prefix="$"
                                        mask="separator" 
                                        thousandSeparator="," 
                                        separatorLimit="10000000"
                                        formControlName="hsaCustom" 
                                        class="form-control" 
                                        (click)="setHSACustom('C')" 
                                        (ngModelChange)="setHSAOOP($event);setHSACustom('C')" >

                                </div>

                                <app-error-message [errorMessage]="formErrors?.hsaCustom"></app-error-message>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="col-12 no-padding ">
                    <div class="intro-text intro-label">If you don't know your annual health care costs, use this comparison chart.</div>
                </div>
            </div>

            <div class="row padded-row justify-content-center">
                <div class="col-sm-12 callout-container-clear">

                    <!-- <form novalidate [formGroup]="formStep2"> -->
                        <div style="" class="row justify-content-center">
                                <div class="col-4 usage-container center" [ngClass]="{'usage-container-selected': hsaUsageL, 'usage-container': !hsaUsageL}">
                                    <div class="costTop">
                                        <div class="col-sm-12 mb-2 green-title-cost">
                                            <span class="">Low usage</span>
                                        </div>
                                        <div class="costIcon" style="height: 85px;"> <img src="assets/img/one_pill_background.png"></div>
                                    
                                            <div class="d-flex justify-content-center mt-3">
                                                <button class="btn btn-select" aria-label="Low usage" [ngClass]="{'btn-selected': hsaUsageL, 'btn-select': !hsaUsageL}" id="hsaUsageL" style="margin-left: 0px;;" type="button" name="hsaUsageL" (click)="setHSALowMedHigh('L')">
                                                    {{ hsaUsageL == true ? 'Selected plan' : 'Select plan' }}
                                                </button>
                                            </div>
                               
                                    </div>
                                    <div class="costMiddle">
                                        <div class="d-flex col-sm-12  mt-3">

                                            <div class="col-1 cost-icon-col"><img  height="38px" class="costIcon" src="assets/img/apple.png" alt="icon-saver"></div>
                                            <div class="col-11 cost-icon-text"><div class="imgtext">You are generally in good health</div></div>
                                        </div>
                                        <div class="d-flex col-sm-12  mt-3">
                                            <div class="col-1 cost-icon-col"><img  height="38px" class="costIcon" src="assets/img/heart.png" alt="icon-saver"></div>
                                            <div class="col-11 cost-icon-text"><div class="imgtext">One preventive visit at your doctor's office and one visit, if any, with your Primary Care Physician</div></div>
                                        </div>
                                        <div class="d-flex col-sm-12  mt-3">
                                            <div class="col-1 cost-icon-col"><img height="38px" class="costIcon" src="assets/img/pill bottle.png" alt="icon-saver"></div>
                                            <div class="col-11 cost-icon-text"><div class="imgtext">One or more prescriptions for common ailments</div></div>
                                        </div>
                                
                                        <div class="col-sm-12 col-bottom">
                                            <div class="green-title-lg green-title-monthlycost">{{oopCosts[OOPProfile.L] | currency:'USD':'symbol':'1.0-0'}}</div>
                                            <div class="green-title-sm mt-3 mb-2">Estimated yearly cost</div>
                                            <div class="green-title-xs">({{oopCosts[OOPProfile.L]/12 | currency:'USD':'symbol':'1.0-0'}} per month)</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-4 usage-container center" [ngClass]="{'usage-container-selected': hsaUsageM, 'usage-container': !hsaUsageM}">
                                    <div class="costTop">
                                        <div class="col-sm-12 mb-2 green-title-cost">
                                            <span class="">Moderate usage</span>
                                        </div>
                                        <div class="costIcon" style="height: 85px;"> <img src="assets/img/two_pill_background.png"></div>
                                        
                                        <div class="d-flex justify-content-center mt-3">
                                            <button class="btn btn-select" aria-label="Medium usage"  [ngClass]="{'btn-selected': hsaUsageM, 'btn-select': !hsaUsageM}"  id="hsaUsageM" style="margin-left: 0px;;" type="button" name="hsaUsageM"  (click)="setHSALowMedHigh('M')">
                                                {{ hsaUsageM == true ? 'Selected plan' : 'Select plan' }}
                                            </button>
                                    
                                        </div>
                                    </div>
                                    <div class="costMiddle">
                                        <div class="d-flex col-sm-12  mt-3">
                                            <div class="col-1 cost-icon-col"><img height="38px" class="costIcon" src="assets/img/apple.png" alt="icon-saver"></div>
                                            <div class="col-11 cost-icon-text"><div class="imgtext">You are in moderate good health with some minor ongoing conditions</div></div>
                                        </div>
                                        <div class="d-flex col-sm-12  mt-3">
                                            <div class="col-1 cost-icon-col"><img height="38px" class="costIcon" src="assets/img/heart.png" alt="icon-saver"></div>
                                            <div class="col-11 cost-icon-text"><div class="imgtext">One preventive visit at your doctor's office and a few sick visits with related prescriptions</div></div>
                                        </div>
                                        <div class="d-flex col-sm-12  mt-3">
                                            <div class="col-1 cost-icon-col"><img height="38px" class="costIcon" src="assets/img/pill bottle.png" alt="icon-saver"></div>
                                            <div class="col-11 cost-icon-text"><div class="imgtext">Up to 6 prescriptions, where some of the prescriptions may be used for chronic conditions</div></div>
                                        </div>
                                        <div class="d-flex col-sm-12  mt-3">
                                            <div class="col-1 cost-icon-col"><img height="38px" class="costIcon" src="assets/img/bed.png" alt="icon-saver"></div>
                                            <div class="col-11 cost-icon-text"><div class="imgtext">You may also have surgery that does not require overnight hospitalization</div></div>
                                        </div>
                                
                                        <div class="col-sm-12 col-bottom">
                                            <div class="green-title-lg green-title-monthlycost">{{oopCosts[OOPProfile.M] | currency:'USD':'symbol':'1.0-0'}}</div>
                                            <div class="green-title-sm mt-3 mb-2">Estimated yearly cost</div>
                                            <div class="green-title-xs">({{oopCosts[OOPProfile.M]/12 | currency:'USD':'symbol':'1.0-0'}} per month)</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 usage-container center" [ngClass]="{'usage-container-selected': hsaUsageH, 'usage-container': !hsaUsageH}">
                                
                                    <div class="costTop">
                                        <div class="col-sm-12 mb-2 green-title-cost">
                                            <span class="">High usage</span>
                                        </div>
                                        <div class="costIcon" style="height: 85px;"> <img src="assets/img/three_pill_background.png"></div>
                                       
                                            <div class="d-flex justify-content-center mt-3">
                                                <button class="btn btn-select" aria-label="High usage"  [ngClass]="{'btn-selected': hsaUsageH, 'btn-select': !hsaUsageH}" id="hsaUsageH" style="margin-left: 0px;;" type="button" name="hsaUsageH"  (click)="setHSALowMedHigh('H')">
                                                    {{ hsaUsageH == true ? 'Selected plan' : 'Select plan' }}
                                            </button>
                                            </div>
                                        
                                    </div>
                                    <div class="costMiddle">
                                        <div class="d-flex col-sm-12  mt-3">
                                            <div class="col-1 cost-icon-col"><img height="38px" class="costIcon" src="assets/img/apple.png" alt="icon-saver"></div>
                                                <div class="col-11 cost-icon-text"><div class="imgtext">You have or may have significant medical needs, chronic conditions or may be having a baby soon</div></div>
                                        </div>
                                        <div class="d-flex col-sm-12  mt-3">
                                            <div class="col-1 cost-icon-col"><img height="38px" class="costIcon" src="assets/img/heart.png" alt="icon-saver"></div>
                                                <div class="col-11 cost-icon-text"><div class="imgtext">One preventive visit at your doctor's office and multiple repeat visits with a Primary Care Physician or specialist</div></div>
                                        </div>
                                        <div class="d-flex col-sm-12  mt-3">
                                            <div class="col-1 cost-icon-col"><img height="38px" class="costIcon" src="assets/img/pill bottle.png" alt="icon-saver"></div>
                                                <div class="col-11 cost-icon-text"><div class="imgtext">Up to 10 or more prescriptions, where most are used to manage chronic condition(s)</div></div>
                                        </div>
                                        <div class="d-flex col-sm-12  mt-3">
                                            <div class="col-1 cost-icon-col"><img height="38px" class="costIcon" src="assets/img/bed.png" alt="icon-saver"></div>
                                            <div class="col-11 cost-icon-text"><div class="imgtext">Major surgery that requires overnight hospitalization</div></div>
                                        </div>
                                
                                        <div class="col-sm-12 col-bottom">
                                            <div class="green-title-lg green-title-monthlycost">{{oopCosts[OOPProfile.H] | currency:'USD':'symbol':'1.0-0'}}</div>
                                            <div class="green-title-sm mt-3 mb-2">Estimated yearly cost</div>
                                            <div class="green-title-xs">({{oopCosts[OOPProfile.H]/12 | currency:'USD':'symbol':'1.0-0'}} per month)</div>
                                        </div>
                                    </div>
                                </div>

                                <input type="hidden" formControlName="hsaUsage">
                                <input type="hidden" formControlName="hsaOOP">
                
                        </div>
                    <!-- </form> -->
                    <div class="row">
                        <div class="col-sm-12 no-padding mt-3">
                            <hr class="hr-thick">
                        </div> 
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="">
                                <app-navigation></app-navigation>
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
</div>


<ng-template #OOPTip>
    <span>
    An out-of-pocket expense is an expense for health care that your insurance doesn't reimburse you for. This can include things like deductibles, coinsurance or items that aren't covered by your insurance.
    </span>
</ng-template>

