export class FormData {

    // step 1
    coverageType = 'S';
    currentAge = 40;
    retirementAge = 65;

    // step 2
    currentHSAParticipant = 'N';
    planBalance = 0;
    fedTax = 24;
    stateTax = 4;
    state = "AL";
    ror = 5;

    // step 3
    hsaOOP = 0;
    hsaUsage = '';
    hsaCustom = 0;

    usageType = '';

    hsaUsageL = false;
    hsaUsageM = false;
    hsaUsageH = false;

    // step 4
    hsaContrib = 0;
    hsaSpend = 0;

    // summary
    hsaContribAlt = 0;
    hsaSpendAlt = 0;

}
/*
    coverageType = 'S';
    currentAge = 21;//40;
    retirementAge = 67;//65;

    // step 2
    currentHSAParticipant = 'N';
    planBalance = 0;
    fedTax = 32;//24;
    stateTax = 4.45;//5;
    state = "LA"; //"AL";
    ror = 9;//5;

    // step 3
    hsaOOP = 0;
    hsaUsage = 'L';
    hsaCustom = 0;

    usageType = 'L';

    hsaUsageL = false;
    hsaUsageM = false;
    hsaUsageH = false;

    // step 4
    hsaContrib = 0;
    hsaSpend = 0;

    // summary
    hsaContribAlt = 0;
    hsaSpendAlt = 0;
    */
