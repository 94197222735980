<div id="results" class="row padded-row">
    <div class="col-lg-8">
        <div class="row">
            <div class="col-sm-12">
                <div class="results-header">
                    <div *ngIf="spenderSaverProfile == 1">
                        Congratulations! You’re an HSA Saver.
                    </div>
                    <div *ngIf="spenderSaverProfile == 2">
                        Saving and paying for health care expenses with an HSA is a
                        smart decision. You're a spender.
                    </div>
                    <div *ngIf="spenderSaverProfile == 3">
                        Congratulations! You are on the path to becoming an HSA Saver.
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="results-intro">
                    <div *ngIf="spenderSaverProfile == 1">
                        Using an HSA to save for health care expenses now or in the
                        future is a wise decision. Taking advantage of tax-free
                        contributions, tax-free growth and tax-free withdrawals for
                        qualified medical expenses will help stretch your health care
                        dollar further in retirement so you don’t have to use funds
                        from your traditional retirement plan to pay for medical expenses.
                    </div>
                    <div *ngIf="spenderSaverProfile == 2">
                        Using an HSA to save for health care expenses now or in the
                        future is a wise decision. There are often trade-offs when
                        balancing todays budget with saving for your future needs.
                        You've already taken a step in the right direction by using an
                        HSA. And, if at any point in the future you're able to increase
                        your savings or decrease your annual spending, then you're already
                        set up and ready to go!
                    </div>
                    <div *ngIf="spenderSaverProfile == 3">
                        Using an HSA to save for health care expenses now or in the future is a great decision.
                        Taking advantage of tax-free contributions, tax-free growth and tax-free withdrawals
                        for qualified medical expenses will help stretch your health care dollar further in
                        retirement so you can save funds in your traditional retirement plan for other living
                        expenses and the things you love to do.
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="results-box blue">
                    <div class="results-box-text">
                        By <span class="results-box-bold">contributing
                            {{formData.hsaContrib | currency:'USD':'symbol':'1.0-0'}}</span> and
                        <span class="results-box-bold">spending
                            {{formData.hsaSpend | currency:'USD':'symbol':'1.0-0'}}</span> annually, your
                        estimated HSA balance at retirement is:
                    </div>
                    <div class="results-box-value results-box-bold">
                        {{projRetBalance | currency:'USD':'symbol':'1.0-0'}}
                    </div>
                    <div class="results-box-text">
                        Your estimated tax savings through retirement is:
                    </div>
                    <div class="results-box-value">
                        {{calculationService.taxSavingsTotal | currency:'USD':'symbol':'1.0-0'}}
                    </div>
                </div>
            </div>
            <div class="col-sm-6" *ngIf="showAlternate">
                <div class="results-box gray">
                    <div class="results-box-text">
                        By <span class="results-box-bold">contributing
                            {{formData.hsaContribAlt | currency:'USD':'symbol':'1.0-0'}}</span> and
                        <span class="results-box-bold">spending
                            {{formData.hsaSpendAlt | currency:'USD':'symbol':'1.0-0'}}</span> annually, you
                        could potentially reach an HSA balance at retirement of:
                    </div>
                    <div class="results-box-value results-box-bold">
                        {{altProjRetBalance | currency:'USD':'symbol':'1.0-0'}}
                    </div>
                    <div class="results-box-text">
                        Your estimated tax savings through retirement is:
                    </div>
                    <div class="results-box-value">
                        {{calculationService.taxSavingsTotalAlt | currency:'USD':'symbol':'1.0-0'}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-4">
        <div class="callout-container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="row-same-height">
                        <div class="col-same-height callout-icon">
                            <img src="assets/img/icon-spender-saver.png" alt="icon-saver-spender">
                        </div>
                        <div class="col-same-height callout-title">
                            <div class="small">Saver vs. Spender</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row callout-text">
                <div class="col-sm-12">
                    <div class="row-same-height">
                        <div class="col-same-height callout-icon center">
                            <img src="assets/img/icon-saver.png" alt="icon-saver">
                        </div>
                        <div class="col-same-height">
                            An HSA saver is someone who saves at least {{spenderSaverPct}}%
                            of their contributions each year for future health care needs.
                        </div>
                    </div>
                </div>
            </div>
            <div class="row callout-text">
                <div class="col-sm-12">
                    <div class="row-same-height">
                        <div class="col-same-height callout-icon center">
                            <img src="assets/img/icon-spender.png" alt="icon-spender">
                        </div>
                        <div class="col-same-height">
                            An HSA spender is someone who saves less than {{spenderSaverPct}}%
                            of their contributions each year by using their HSA to pay for
                            current medical expenses.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-sm-8">
        <div class="results-text">
            <p>
                Consider increasing your annual contribution amount to reach your goal.
            </p>
            <p>
                If your employer helps manage your HSA program, you can update your
                contribution directly with your employer or make a direct contribution
                through the member website.
            </p>
            <div class="divider"><span>OR</span></div>
            <p>
                If you have an individual account, you can update your contribution
                through the member website at any time.
            </p>
        </div>
        <!-- <div class="results-login-container">
            <a href="https://myhealth.bankofamerica.com/Login.aspx?ReturnUrl=%2f" target="_blank" class="btn btn-blue">
                Click to login
            </a>
        </div> -->
    </div>
    <div class="col-sm-4 hidden-xs">
        <!-- <img src="assets/img/icon-hsa-person.png" alt="hsa-person" class="img-responsive"> -->
        <img src="assets/img/HSAPaymentImage.png" alt="hsa-person" class="img-responsive hsaPaymentImage">

    </div>


    <div class="col-sm-12">
        <hr class="gray">
    </div>


    <div class="row-same-height">
        <div class="col-sm-12">
            <div class="row">
                <div *ngFor="let item of actionItems; let i = index;" [class.add-border]="i < 2" class="action-item col-md-4">
                    <app-action-item [actionItem]="item" [index]="i"></app-action-item>
                </div>
            </div>
        </div>
    </div>

</div>