/**
 * OOP Costs.
 * Based on:
 *     age range
 *     coverage (single vs family)
 *     HSA usage (low / moderate / high)
 */ 
// export const OOP_COSTS_OLD = {
//     A100100: 250,         // 20-34 : S : L
//     A100200: 3500,        // 20-34 : S : M
//     A100300: 5500,        // 20-34 : S : H
//     B100100: 650,         // 20-34 : F : L
//     B100200: 7300,        // 20-34 : F : M
//     B100300: 10000,        // 20-34 : F : H
//     C100100: 250,         // 35-49 : S : L
//     C100200: 3700,        // 35-49 : S : M
//     C100300: 5000,        // 35-49 : S : H
//     D100100: 800,        // 35-49 : F : L
//     D100200: 7700,        // 35-49 : F : M
//     D100300: 10000,        // 35-49 : F : H
//     E100100: 700,         // 50+ : S : L
//     E100200: 3800,        // 50+ : S : M
//     E100300: 5000,        // 50+ : S : H
//     F100100: 1800,        // 50+ : F : L
//     F100200: 8800,        // 50+ : F : M
//     F100300: 10000,        // 50+ : F : H
// };

export const OOP_COSTS = {
    A100100: 250,         // 20-34 : S : L
    A100200: 606,        // 20-34 : S : M
    A100300: 3036,        // 20-34 : S : H
    B100100: 1562,         // 20-34 : F : L
    B100200: 5274,        // 20-34 : F : M
    B100300: 9488,        // 20-34 : F : H
    C100100: 250,         // 35-49 : S : L
    C100200: 606,        // 35-49 : S : M
    C100300: 3036,        // 35-49 : S : H
    D100100: 1562,        // 35-49 : F : L
    D100200: 5274,        // 35-49 : F : M
    D100300: 9488,        // 35-49 : F : H
    E100100: 250,         // 50+ : S : L
    E100200: 606,        // 50+ : S : M
    E100300: 3036,        // 50+ : S : H
    F100100: 1562 ,        // 50+ : F : L
    F100200: 5274,        // 50+ : F : M
    F100300: 9488,        // 50+ : F : H
};
