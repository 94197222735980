<div class="page-header">
    <div class="d-block w-100 mb-3 mt-2" *ngIf="!firstPage" >
        <div class="row">
            <div class="col-sm-8 offset-sm-2 col-12">
                <div class="row justify-content-center">
                    <div class="navalign hide-hr">
                        <div class="navcircle"><img src='assets/img/ic_check_24px.png' alt="icon-saver"></div>
                        <div class="navtext">About you</div>
                    </div> 
                    <div class="navalign ">
                        <hr class="hr-hdr"/>
                    </div>
                    <div class="navalign hide-hr center">
                        <div class="" [ngClass]="{'navcircle-x': navcontent2 == 'X', 'navcircle navcircle-border': navcontent2 == '2', 'navcircle': navcontent2 == '✔'}">
                            <div *ngIf="navcontent2 == '✔'">
                                <img src='assets/img/ic_check_24px.png' alt="icon-saver">
                            </div>
                            <div *ngIf="navcontent2 != '✔'" class="">
                                {{ navcontent2 }}
                            </div>
                        </div>
                        <div class="navtext">Costs</div>
                    </div>
                    <div class="navalign">
                        <hr class="hr-hdr"/>
                    </div>
                    <div class="navalign hide-hr center">
                        <div class="" [ngClass]="{'navcircle-x': navcontent3 == 'X', 'navcircle navcircle-border': navcontent3 == '3', 'navcircle': navcontent3 == '✔'}">
                            <div *ngIf="navcontent3 == '✔'">
                                <img src='assets/img/ic_check_24px.png' alt="icon-saver">
                            </div>
                            <div *ngIf="navcontent3 != '✔'" class="">
                                {{ navcontent3 }}
                            </div>
                        </div>
                        <div class="navtext">
                            Contributions
                        </div>
                    </div>
                    <div class="navalign">
                        <hr class="hr-hdr"/>
                    </div>
                    <div class="navalign hide-hr">
                        <div class="" [ngClass]="{'navcircle-x': navcontent4 == 'X', 'navcircle navcircle-border': navcontent4 == '4', 'navcircle': navcontent4 != ''}">
                            {{ navcontent4 }}</div>
                        <div class="navtext">
                            Summary
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

