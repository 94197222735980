import { Component, OnInit, OnDestroy, LOCALE_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { formatCurrency, formatNumber } from '@angular/common';
import { NgControl } from '@angular/forms';


import { PageService } from '../pages.service';
import { PageData } from '../pages.model';

import { FormDataService } from '../shared/form-data/form-data.service';
import { FormData } from '../shared/form-data/form-data.model';
import { ValidationService } from '../shared/form-validations/validation.service';
import { CustomValidators } from 'ng2-validation';
import { CalculationsService } from '../shared/calculations/calculations.service';

import { OOP_CONTENT } from '../shared/oop/oop-content';
import { OOP_COSTS } from '../shared/oop/oop-costs';
import { OOPProfile } from '../shared/oop/oop-profile.type';
import { UtilService } from '../../shared/services/util.service';
import { AccordionComponent } from 'ngx-bootstrap/accordion';
import { CurrencyPipe } from '@angular/common';
import { CurrencyMaskModule } from "ng2-currency-mask";

import * as $ from 'jquery';

// import { ValueConverter } from '@angular/compiler/src/render3/view/template';







@Component({
    selector: 'app-step2',
    templateUrl: './step2.component.html',
    styleUrls: ['./step2.component.css']
})
export class Step2Component implements OnInit, OnDestroy {

    name = 'Angular';
formattedAmount;
amount;


    pageData: PageData;

    formStep2: FormGroup;
    formData: FormData;
    formDefaults: FormData;
    formErrors: any;
    oneAtATime: boolean = true;

    activeUsage: string;
    lowCollapsed = false;
    medCollapsed = false;
    highCollapsed = false;
    hsaUsage: string;
    usageType: string;
    openLow: boolean;
    openMod: boolean;
    openHigh: boolean;
    customOOPValidations: Array<any> = [];

    OOPProfile: OOPProfile;

    formSubscription: any;
    currencyMask: any;
    percentMask: any;
    numberMask: any;

    hsaUsageL: boolean;
    hsaUsageM: boolean;
    hsaUsageH: boolean;

    selected1: boolean;
    selected2: boolean;
    selected3: boolean;

    hsaCustom: string;


    /**
     * Creates an instance of step2Component.
     * @param {PageService} pageService
     * @param {FormBuilder} formBuilder
     * @param {FormDataService} formDataService
     * @param {ValidationService} validationService
     * @param {CalculationsService} calculationService
     *
     * @memberOf step2Component
     */
    constructor(private pageService: PageService,
        private formBuilder: FormBuilder,
        private formDataService: FormDataService,
        private validationService: ValidationService,
        public  utilService: UtilService,
        private currencyPipe: CurrencyPipe,
        private calculationService: CalculationsService) { }



transformAmount(element){
    this.formattedAmount = this.currencyPipe.transform(element.target.value, '$');

    element.target.value = this.formattedAmount;
}

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf step2Component
     */
    ngOnInit() {

        this.hsaUsageL = false;
        this.hsaUsageM = false;
        this.hsaUsageH = true;

        this.selected1 = false;
        this.selected2 = true;
        this.selected3 = false;

        this.initPageData();
        this.initFormData();
        
        this.initOOPContent();

        this.initUsage();
        this.initMasks();

        // console.log("ngOnInit");

        // $("#hsaUsageL").prop( "checked", true );
        // $("#hsaUsageM").prop( "checked", false );
        // $("#hsaUsageH").prop( "checked", false );

        // ;

        // utilService.fmt()
//$("#hsaCustom").val(this.formatCurrency($("#hsaCustom").val));

        // let tmp = this.formatCurrency($("#hsaCustom"),"");
        // $("#hsaCustom").value(tmp);
        // alert( $("#hsaCustom").value());

        if (parseInt(this.stripfmt(this.formDataService.getValue('hsaCustom'))) > 0) {
            this.formStep2.patchValue({hsaCustom: this.stripfmt(this.formDataService.getValue('hsaCustom'))});
        } else {
            this.setHSALowMedHigh(this.formDataService.getValue('hsaUsageL'));
        }


        //this.hsaCustom = this.formDataService.getValue('hsaCustom');
        //alert(this.hsaCustom);
        
        // this.formStep2.patchValue({
        //     hsaCustom: this.currencyPipe.transform(this.formStep2.controls['hsaCustom'].value.replace(/\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1-0-0')}, {emitEvent: false});
      

        // $("input[data-type='currency']").on({
        //     keyup: function() {
        //     this.formatCurrency($(this));
        //     },
        //     blur: function() { 
        //     },
        //     ready: function() { 
        //         this.formatCurrency($(this), "blur");
        //     },
        // });
        

    }

    initMasks() {

       // this.currencyMask = this.utilService.getCurrencyMask();
        // this.percentMask = this.utilService.getPercentageMask();
        // this.numberMask = this.utilService.getNumberMask();
    }

    /**
     * Destroy actions when Angular is done with component.
     *
     * @memberOf step2Component
     */
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }

    /**
     * Inits page specific data and sends this data to the page service.
     *
     * @memberOf step2Component
     */
    initPageData() {
        this.pageData = {
            currentPage: 'step2',
            nextPage: 'step3',
            prevPage: 'step1',
            stepNum: 2,
            progress: 75,
            firstPage: false,
            lastPage: false,
            showProgress: true,
            stepTitle: 'Annual health care costs',
            stepIcon:""
        };
        this.pageService.initPage(this.pageData);

   
       
    }
    //this.formStep2.patchValue({ hsaCustom: 0 });

    /**
     * Init data needed for form.
     *
     * @memberOf step2Component
     */
    initFormData() {
        this.formDefaults = this.formDataService.getDefaults();
        this.formData = this.formDataService.getFormData();
        this.formErrors = this.validationService.validationErrors;

        this.buildForm();
    }

    /**
     * Use FormBuilder to create form.
     * Subscribe to form value changes and validate.
     * Save data to form data service if valid.
     * Calculate values as needed.
     *
     * @memberOf step2Component
     */
    buildForm() {
        // validations for custom OOP field
        this.customOOPValidations = [
            Validators.required,
            CustomValidators.gt(this.validationService.customOOPGreaterThan),
            CustomValidators.lt(this.validationService.customOOPLessThan)
        ];

        // build step 3 form
        this.formStep2 = this.formBuilder.group({
            usageType: [this.formData.usageType, null],
            hsaUsage: [this.formData.hsaUsage, Validators.required],
            hsaOOP: [this.formData.hsaOOP, Validators.required],
            hsaCustom: [this.formData.hsaCustom, (this.formData.hsaUsage === 'C' ? this.customOOPValidations : null)],
            hsaUsageL: [this.formData.hsaUsageL, null],
            hsaUsageM: [this.formData.hsaUsageM, null],
            hsaUsageH: [this.formData.hsaUsageH, null],
        });

        // set current form data in formDataService
        this.formDataService.currentFormGroup = this.formStep2;

        // subscribe to step 3 form changes
        this.formSubscription = this.formStep2.valueChanges.subscribe(data => {

 
            this.formErrors = this.validationService.validateFormData(data, this.formStep2);
            if (this.formStep2.valid) {
                this.formDataService.setFormData(data);
            }
        });

        this.openLow = (this.formData.hsaUsage === "L");
        this.openMod = (this.formData.hsaUsage === "M");
        this.openHigh = (this.formData.hsaUsage === "H");
    }

  

// if (data.hsaCustom) {
//     this.formStep2.patchValue({
//         hsaCustom: this.currencyPipe.transform(data.hsaCustom.replace(/\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '1-0-0')}, {emitEvent: false});
// }


    /**
     * Get OOP_COSTS constant in HTML.
     *
     * @readonly
     *
     * @memberOf step2Component
     */
    get oopCosts() {
        return OOP_COSTS;
    }

    /**
     * Get OOP_CONTENT constant in HTML.
     *
     * @readonly
     *
     * @memberOf step2Component
     */
    get oopContent() {
        return OOP_CONTENT;
    }

    /**
     * Set OOPProfile in calc service.
     *
     * @memberOf step2Component
     */
    initOOPContent() {
        this.calculationService.setOOPProfile(this.formData.currentAge, this.formData.coverageType);
        this.OOPProfile = this.calculationService.OOPProfile;
    }

    /**
     * Init HSA usage.
     * Get OOP cost from calc service for this user.
     * Set L/M/H/C usage in form.
     * Set OOP cost in form.
     *
     * @memberOf step2Component
     */
    initUsage() {

        //hsaCustom
        // const control = this.formStep2.get('hsaCustom');
        const hsaCustom = parseInt(this.stripfmt(this.formDataService.getValue('hsaCustom')));
        const hsaUsage = (this.formDataService.getValue('hsaUsage') != null ? this.formDataService.getValue('hsaUsage') : 'L');
        

        if (hsaCustom > 0) {
            this.setHSACustom('C');
            this.hsaUsage = 'C'; 
        } else {
            this.hsaUsage = hsaUsage;
            this.setHSALowMedHigh(hsaUsage);
        }
        
        // console.log("initUsage");
    }

    stripfmt(s) {
        // var i = 0;
        // s = s + "";
        // if (s == null || s == "") {
        //     return "0";
        // }
        // // must be called fmultiple times for the commas - does not replace all chars with one call 
        // for (i = 0; i < 10; i++) {
        //     s = s.replace(/\,/g, '');
        // }
        // s = s.replace(/\$/g, "");
        // s = s.replace(/\%/g, "");
        // if (s == "" || s == " ") {
        //     s = "0";
        // }
        // format back to a number/float
        return s;
    } //stripFormatting

    setEmpty(event: any){

    event.target.value = "";

    }
    /**
     * Set HSA OOP L/M/H usage and values.
     *
     * @param {string} usage
     *
     * @memberOf step2Component
     */
    setHSALowMedHigh(usage: string) {

        if (usage !== "") {

            this.usageType = usage;
            this.formDataService.getFormData()
            this.formStep2.patchValue({ hsaCustom: 0 });
            this.setHSAUsage(usage);
            this.setHSAOOP(OOP_COSTS[this.OOPProfile[usage]]);
            this.updateCustomControl();
            this.calculationService.calcDefaultHSAContribSpend('forcespend');
            this.formDataService.setValue('hsaUsageL', usage);
            this.formDataService.setValue('hsaUsage', usage);

            this.formDataService.setValue('hsaSpend', 0);

        }
    }


    setHSACustomVal(element: any) {

        // Remove or comment this line if you dont want 
        // to show the formatted amount in the textbox.
        element.target.value = (element.target.value === null || element.target.value === "" ? 0 : element.target.value );
        this.formDataService.setValue('hsaCustom', this.stripfmt(element.target.value));

        this.setHSACustom(element);


    }


    /**
     * Set HSA OOP custom usage and value.
     *
     * @param {string} usage
     *
     * @memberOf step2Component
     */
    setHSACustom(usage: string) {

        this.setHSAUsage(usage);
        this.setHSAOOP(this.stripfmt(this.formStep2.controls['hsaCustom'].value));
        // this.formStep2.patchValue({ hsaCustom: this.formStep2.controls['hsaCustom'].value });
        this.formDataService.setValue('hsaCustom', this.stripfmt(this.formStep2.controls['hsaCustom'].value));
        this.formDataService.setValue('hsaUsage', usage);
        this.updateCustomControl();

        this.formDataService.setValue('hsaSpend', 0);

    }

    /**
     * Sets active usage for L/M/H/C and sets form value.
     *
     * @param {string} usage
     *
     * @memberOf step2Component
     */
    setHSAUsage(usage: string) {
        // console.log("initUsage");console.log("setHSAUsage: " + usage);
        this.hsaUsage = usage;
        if (usage === 'C') {
            $("#hsaUsageL").prop( "checked", false );
            $("#hsaUsageM").prop( "checked", false );
            $("#hsaUsageH").prop( "checked", false );
        } 

        this.hsaUsageL = false;
        this.hsaUsageM = false;
        this.hsaUsageH = false;
        
        $("#hsaUsageL").prop( "checked", false );
        $("#hsaUsageM").prop( "checked", false );
        $("#hsaUsageH").prop( "checked", false );

        if (this.hsaUsage === "L") { 
            this.hsaUsageL = true;$("#hsaUsageL").prop( "checked", true ); 
        }
        if (this.hsaUsage === "M") { 
            this.hsaUsageM = true;$("#hsaUsageM").prop( "checked", true ); 
        }
        if (this.hsaUsage === "H") { 
            this.hsaUsageH = true;$("#hsaUsageH").prop( "checked", true ); 
        }

        this.activeUsage = usage;
        this.formStep2.patchValue({ hsaUsage: usage });

        this.formDataService.setValue('hsaSpend', 0);
        
    }

    /**
     * Sets OOP value in form.
     *
     * @param {number} oop
     *
     * @memberOf step2Component
     */
    setHSAOOP(oop: number) {

        this.formStep2.patchValue({ hsaOOP: oop });
    }

    /**
     * Toggle validations on custom control.
     *
     * @memberOf step2Component
     */
    updateCustomControl() {



        // let myval = this.stripfmt(this.formStep2.get('hsaCustom').value);

        // this.formDataService.setValue('hsaCustom', myval);

        
        // this.formStep2.value.hsaCustom = this.stripfmt(this.formStep2.value.hsaCustom);
        // console.log( this.formStep2.value.hsaCustom    );
        
        // tmp = 
        //  let val= {
        //     hsaCustom: tmp
        //   };
        //let tmp = this.stripfmt(this.formStep2.controls['hsaCustom'].value);
        // this.formStep2.controls['hsaCustom'].setValue() = tmp

        // this.formStep2.controls['hsaCustom'].value( {
        //     hsaCustom: tmp
        //   });


        const control = this.formStep2.get('hsaCustom');

        // control.setValue(parseInt(this.utilService.stripfmt(control.value)));
        if (this.formStep2.controls['hsaUsage'].value === 'C') {
            control.setValidators(this.customOOPValidations);
        } else {
            control.setValidators(null);
        }
        control.updateValueAndValidity();
    }




    
    
    //  formatNumber(n) {
    //   // format number 1000000 to 1,234,567
    //   return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // }
    
    
    //  formatCurrency(input, blur) {
    //   // appends $ to value, validates decimal side
    //   // and puts cursor back in right position.
      
    //   // get input value
    //   var input_val = input.val();
      
    //   // don't validate empty input
    //   if (input_val === "") { return; }
      
    //   // original length
    //   var original_len = input_val.length;
    
    //   // initial caret position 
    //   var caret_pos = input.prop("selectionStart");
        
    //   // check for decimal
    //   if (input_val.indexOf(".") >= 0) {
    
    //     // get position of first decimal
    //     // this prevents multiple decimals from
    //     // being entered
    //     var decimal_pos = input_val.indexOf(".");
    
    //     // split number by decimal point
    //     var left_side = input_val.substring(0, decimal_pos);
    //     var right_side = input_val.substring(decimal_pos);
    
    //     // add commas to left side of number
    //     left_side = this.formatNumber(left_side);
    
    //     // validate right side
    //     right_side = this.formatNumber(right_side);
        
    //     // On blur make sure 2 numbers after decimal
    //     if (blur === "blur") {
    //       right_side += "00";
    //     }
        
    //     // Limit decimal to only 2 digits
    //     right_side = right_side.substring(0, 2);
    
    //     // join number by .
    //     input_val = "$" + left_side + "." + right_side;
    
    //   } else {
    //     // no decimal entered
    //     // add commas to number
    //     // remove all non-digits
    //     input_val = this.formatNumber(input_val);
    //     input_val = "$" + input_val;
        
    //     // final formatting
    //     if (blur === "blur") {
    //       input_val += ".00";
    //     }
    //   }
      
    //   // send updated string to input
    //   input.val(input_val);
    
    //   // put caret back in the right position
    //   var updated_len = input_val.length;
    //   caret_pos = updated_len - original_len + caret_pos;
    //   input[0].setSelectionRange(caret_pos, caret_pos);
    // }
    


    

}
