<div class="action-container">
    <div class="action-header">
        <div class="row-same-height">
            <div class="col-same-height action-icon" [class.action-link]="hasLink" (click)="navLink(actionItem?.linkURL)">
                <img src="assets/img/{{actionItem.icon}}" alt="icon" width="50">
            </div>
            <div class="col-same-height action-title" [class.action-link]="hasLink" (click)="navLink(actionItem?.linkURL)">
                {{actionItem.title}}
            </div>
        </div>
    </div>
    <div class="action-body">
        <div *ngIf="actionItem.text?.length">
            <p *ngFor="let text of actionItem.text">
                {{text}}
            </p>
        </div>
        <div *ngIf="actionItem.list?.length">
            <ol>
                <li *ngFor="let list of actionItem.list">{{list}}</li>
            </ol>
        </div>
    </div>
</div>