<title>Step 3</title>
<div id="step3" class="row justify-content-center">
    <div class="col-sm-12">
        <div class="callout-container-shaded pl-5 pr-5">
            <div class="row padded-row mt-3">
                <div class="col-sm-12 no-padding">
                    <div class="green-title-lg">
                        Contributions & expenses
                    </div>
                    <div class="intro-label  mt-3">
                        By leveraging the power of contributions and expenses to your health savings account (HSA), you 
                        can enhance your retirement savings while preparing for potential health care costs down the road.
                    </div>
                </div>
            </div>

            <div class="row padded-row mt-3">
                <div class="callout-container col-md-12">
                    <div class="row">
                        <div class="col-md-7 col-12 no-padding-right">
                            <form novalidate [formGroup]="formStep3">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-sm-11 mb-3">
                                                <div class="input-label">How much will you and/or your employer contribute  
                                                    <span class="nowrap">annually to your HSA?  <img triggers="click" placement="top" [outsideClick]="true" [popover]="EmpCostTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png"></span>
                                                </div>
                                            </div>
                                            <div class="col-sm-11 ml-3 mr-4">
                                                <div class="row form-group slider-2">
                                                    <div class="col-1 no-pad-col">
                                                        <div class="text-right slider-end-label">{{validationService.hsaMinContrib | currency:'USD':'symbol':'1.0-0'}}</div>
                                                        <div class="sliderSubLabel">(annually)</div>
                                                    </div>
                                                    <div class="col-10">
                                                        <span id="hsaContriblabel" class="sr-only">HSA Contributions Slider</span>
                                                        <input id="hsaContrib" aria-label="HSA Contributions" formControlName="hsaContrib" type="text">
                                                    </div>
                                                    <div class="col-1 no-pad-col">
                                                        <div class="text-right slider-end-label">{{validationService.hsaMaxContrib | currency:'USD':'symbol':'1.0-0'}}</div>
                                                        <div class="text-right sliderSubLabel">(annually)</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row input-row-large">
                                            <div class="col-sm-11 mb-3">
                                                <div class="input-label ">
                                                    How much of your annual out-of-pocket health care costs 
                                                    of <span class="text-value">{{formData.hsaOOP | currency:'USD':'symbol':'1.0-0'}}</span> 
                                                    will you pay with your 
                                                    <span class="nowrap">HSA? <img triggers="click" placement="top" [outsideClick]="true" 
                                                        [popover]="OOPCostTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png"></span>
                                                </div>
                                            </div>
                                            <div class="col-sm-11 ml-3 mr-4 ">
                                                <div class="row form-group slider-2">
                                                    <div class="col-1 no-pad-col">
                                                        <div class="text-right slider-end-label">{{validationService.hsaMinSpend | currency:'USD':'symbol':'1.0-0'}}</div>
                                                        <div class="sliderSubLabel">(annually)</div>
                                                    </div>
                                                    <div class="col-10">
                                                        
                                                        <span id="hsaSpendlabel" class="sr-only">HSA out-of-pocket health care costs Slider</span>
                                                        <input id="hsaSpend" formControlName="hsaSpend" type="text">
                                                    </div>
                                                    <div class="col-1 no-pad-col">
                                                        <div class="text-right slider-end-label">{{validationService.hsaMaxSpend | currency:'USD':'symbol':'1.0-0'}}</div>
                                                        <div class="text-right sliderSubLabel">(annually)</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-md-4 offset-md-1 offset-1 col-10 step3Tip">
                            <div class="row w-100">
                                <div class="col-sm-12 mb-2">
                                    <img src="assets/img/tip_jar.png" class="infoicon icon-tip-jar" height="70" alt="icon-tip-jar"> 
                                    <span class="green-tip-hdr text-bold">Tip</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 border-left">
                                    <div class="callout-text">
                                        You own your HSA. Your money moves with you if you change jobs, health plans or begin 
                                        retirement. There are no use-it-or lose it rules, the balance rolls over from year to 
                                        year. Money contributed to an HSA (even earned interest!) is exempt from federal and 
                                        state income taxes (in most states).
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-sm-12 ">
                            <hr class="hr-thick-fullwidth">
                            <div class="mt-4">
                                <app-navigation></app-navigation>
                            </div> 
                        </div> 
                    </div>

                </div>
            
            </div>
        </div>
    </div>
</div>

<ng-template #EmpCostTip>
    <span>
        Note: The HSA contribution limits for {{constants.currentYear}} are 
        {{constants.HSA_SINGLE  | currency:'USD':'symbol':'1.0-0'}} for individual coverage and 
        {{constants.HSA_FAMILY  | currency:'USD':'symbol':'1.0-0'}} for family coverage. Those 
        55 and older can contribute an additional 
        {{constants.HSA_CATCHUP  | currency:'USD':'symbol':'1.0-0'}} as a catch-up contribution.
    </span>
</ng-template>

<ng-template #OOPCostTip>
    <span>
        Out-of-pocket expenses are any expenses for health care that your insurance doesn't reimburse 
        you for. These can include things like deductibles, coinsurance, or items that aren't covered by your insurance.
    </span>
</ng-template>

