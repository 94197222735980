import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { FormData } from '../../pages/shared/form-data/form-data.model';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';

@Injectable()
export class SessionService {

    timeoutMinutes = 30;
    timedOut = false;

    /**
     * Creates an instance of SessionService.
     *
     * @memberOf SessionService
     */
    constructor(private idle: Idle,
                private router: Router) {
        // get seconds from timeoutMinutes property
        const timeoutSeconds = this.timeoutMinutes * 60;
        // sets an idle timeout (seconds)
        idle.setIdle(timeoutSeconds);
        // sets a timeout period (seconds) after idle time expires, in which user will officially be timed out
        idle.setTimeout(1);
        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        // actions when timeout detected
        idle.onTimeout.subscribe(() => {
            this.clearSession();
            this.timedOut = true;
            this.router.navigate(['/session']);
        });
        // reset session timer on page load
        this.resetSession();
    }

    resetSession() {
        this.idle.watch();
        this.timedOut = false;
    }

    /**
     * Save form inputs from an object to session storage.
     *
     * @param {*} data
     *
     * @memberOf SessionService
     */
    saveFormInputs(data: any) {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                sessionStorage.setItem(key, data[key]);
            }
        }
    }

    /**
     * Get form inputs from session storage.
     *
     * @returns {Array}
     *
     * @memberOf SessionService
     */
    getFormInputs() {
        const formData: FormData = new FormData();
        const formInputs: Array<any> = [];
        Object.keys(formData).forEach(key => {
            formInputs[key] = sessionStorage.getItem(key);
        });
        return formInputs;
    }

    /**
     * Save a single key/value item to session storage.
     *
     * @param {string} key
     * @param {*} value
     *
     * @memberOf SessionService
     */
    saveSessionItem(key: string, value: any) {
        sessionStorage.setItem(key, value);
    }

    /**
     * Get a single key/value from session storage.
     *
     * @param {string} key
     * @returns {*}
     *
     * @memberOf SessionService
     */
    getSessionItem(key: string) {
        return sessionStorage.getItem(key);
    }

    /**
     * Get all items from session storage.
     *
     * @returns
     *
     * @memberOf SessionService
     */
    getAllSessionItems() {
        const items: Array<any> = [];
        for (let i = 0; i < sessionStorage.length; i++) {
            const key = sessionStorage.key(i);
            items[key] = sessionStorage.getItem(key);
        }
        return items;
    }

    clearSession() {
        sessionStorage.clear();
    }

}
