import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';


declare const ga: Function;
declare const gtag: Function;

@Injectable({
    providedIn: 'root'
})
export class ApiService {


    jsonPDFData: any;

    constructor(private httpClient: HttpClient,
        private router: Router
    ) { }



    getPDF(calcdata: any): Observable<any> {
        //console.log("getPDF");
        if (!calcdata) {
            return null;
        }

        gtag('event', 'page_view', {
            page_title: '/Create PDF'
        });
        
        // PHP POST CALL
        var myheaders = new HttpHeaders();
        myheaders.append('Content-Type', 'text/plain');
        myheaders.append('Content-Type', 'application/json');
        myheaders.append('Access-Control-Allow-Origin', '*');

        var thisURL = ""; // "http://fidelity-hsa.local:8073/report/hsareport.php";
        let url = location.origin;
        // console.log("url before: " + url);
        if (url.indexOf("cloud") == -1) {

            thisURL = "http://fidhsac/report/hsareport.php";
            // thisURL = url + "/report/hsareport.php";
            // console.log("thisURL 1: " + thisURL);
        } else {

            if (url.indexOf("fidelity-hsacalculator-uat") != -1) {
                thisURL = "https://fidelity-hsacalculator-uat.ssnc.cloud/report/hsareport.php";
            } else  if (url.indexOf("fidelity-hsacalculator-kc") != -1 ) {
                thisURL = "https://fidelity-hsacalculator-kc.ssnc.cloud/report/hsareport.php";
            } else  if (url.indexOf("fidelity-hsacalculator-stl") != -1 ) {
                thisURL = "https://fidelity-hsacalculator-stl.ssnc.cloud/report/hsareport.php";
            } else  if (url.indexOf("fidelity-hsacalculator") != -1 ) {
                thisURL = "https://fidelity-hsacalculator.ssnc.cloud/report/hsareport.php";
            } else {
                thisURL = "https://fidelity-hsacalculator.ssnc.cloud/report/hsareport.php";
            }


        }

        // console.log("thisURL: " + thisURL + ", calcdata: " + calcdata);
        return this.httpClient.post<any>(thisURL, calcdata, { headers: myheaders });

    }

}
