import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

import { PageService } from '../../pages.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { PageData } from '../../pages.model';

import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

    environmentName: string = environment.environmentName;

    totalSteps = 4;

    step: number;
    progress: number;
    showProgress: boolean;
    firstPage: boolean;
    stepTitle: string;
    stepIcon: string;

    stepIcon1: string;
    stepIcon2: string;
    stepIcon3: string;
    stepIcon4: string;
    navcontent1: string;
    navcontent2: string;
    navcontent3: string;
    navcontent4: string;

    private pageSubscription: any;

    /**
     * Creates an instance of HeaderComponent.
     * @param {PageService} pageService
     *
     * @memberOf HeaderComponent
     */
    constructor(private pageService: PageService,
        private cdr: ChangeDetectorRef) {}

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf HeaderComponent
     */
    ngOnInit() {
        this.pageSubscription = this.pageService.pageChange.subscribe((data) => {
            this.showProgress = data.showProgress;
            this.progress = data.progress;
            this.step = data.stepNum;
            this.stepTitle = data.stepTitle;
            this.firstPage = data.firstPage;
            this.cdr.detectChanges();

            //console.log("Step: " + this.step + ", Name" + this.stepTitle);
            this.stepIcon1 = "check.png";
            this.stepIcon2 = "x-out.png";
            this.stepIcon3 = "x-out.png";
            this.stepIcon4 = "x-out.png";
            this.pageService.setIcons(this.stepIcon1, this.stepIcon2, this.stepIcon3, this.stepIcon4);

            this.stepIcon1 = this.stepIcon1; // this.pageService.getIcon(1);
            this.stepIcon2 = this.stepIcon2; // this.pageService.getIcon(2);
            this.stepIcon3 = this.stepIcon3; // this.pageService.getIcon(3);
            this.stepIcon4 = this.stepIcon4; // this.pageService.getIcon(4);

            // let check = "";
            // let check = "<img src='assets/img/ic_check_24px.png'><
            let check = '✔';
            this.navcontent1 = "1";
            this.navcontent2 = "2";
            this.navcontent3 = "3";
            this.navcontent4 = "4";
            switch (this.step) {
                case 1:
                    this.navcontent1 = "1";
                    this.navcontent2 = "X";
                    this.navcontent3 = "X";
                    this.navcontent4 = "X";
                  break;
                  
                case 2:
                    this.navcontent1 = check;
                    this.navcontent2 = "2";
                    this.navcontent3 = "X";
                    this.navcontent4 = "X";
                  break;
    
                case 3:
                    this.navcontent1 = check;
                    this.navcontent2 = check;
                    this.navcontent3 = "3";
                    this.navcontent4 = "X";
                    break;
    
                case 4:
                    this.navcontent1 = check;
                    this.navcontent2 = check;
                    this.navcontent3 = check;
                    this.navcontent4 = "4";
                    break;
    
                default:
                  break;
              }

            // &#10003;
        });
    }

    /**
     * Destroy actions when Angular is done with component.
     *
     * @memberOf HeaderComponent
     */
    ngOnDestroy() {
        this.pageSubscription.unsubscribe();
    }

}
