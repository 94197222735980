import { Component, OnInit } from '@angular/core';

import { PageService } from '../pages.service';
import { PageData } from '../pages.model';
import { CalculationsService } from '../shared/calculations/calculations.service';
import { CONSTANTS } from '../shared/calculations/calc-constants';
import { FormData } from '../shared/form-data/form-data.model';
import { FormDataService } from '../shared/form-data/form-data.service';
import { ProjResults } from '../shared/calculations/proj-results.type';
import { ActionItem } from './action-item/action-item.type';


@Component({
    selector: 'app-results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {

    pageData: PageData;

    formData: FormData;

    spenderSaverProfile: number;
    flagSpender: boolean;
    flagSaver: boolean;
    spenderSaverPct: number;

    projResults: ProjResults;
    altProjResults: ProjResults;
    projRetBalance: number;
    altProjRetBalance: number;

    showAlternate: boolean;

    actionItems: Array<ActionItem> = [];

    constructor(private pageService: PageService,
        public calculationService: CalculationsService,
        private formDataService: FormDataService) { }

    ngOnInit() {
        this.initPageData();
        this.initVariables();
        this.initActionItems();
    }

    initPageData() {
        this.pageData = {
            currentPage: 'results',
            nextPage: '',
            prevPage: 'summary',
            stepNum: 4,
            progress: 0,
            firstPage: false,
            lastPage: true,
            showProgress: false,
            stepTitle: 'Summary and planning',
            stepIcon: ''
        };
        this.pageService.initPage(this.pageData);
    }

    initVariables() {

        // form data
        this.formData = this.formDataService.getFormData();

        // spend save pct from engine
        this.spenderSaverPct = CONSTANTS.SPEND_SAVE_PCT;

        // spender saver profile
        // 1 = saver
        // 2 = spender
        // 3 = spender who becomes saver in alternate scenario
        const baseSpenderSaver = this.calculationService.flagSpenderSaver;
        const altSpenderSaver = this.calculationService.flagSpenderSaverAlt;

        if (baseSpenderSaver === 'saver') {
            this.spenderSaverProfile = 1;
        } else if (baseSpenderSaver === 'spender' && altSpenderSaver === 'spender') {
            this.spenderSaverProfile = 2;
        } else if (baseSpenderSaver === 'spender' && altSpenderSaver === 'saver') {
            this.spenderSaverProfile = 3;
        }

        // projection results
        this.projResults = this.calculationService.projResults;
        this.projRetBalance = this.projResults.retirementBalance;
        this.altProjResults = this.calculationService.altProjResults;
        this.altProjRetBalance = this.altProjResults.retirementBalance;

        // show alternate flag
        this.showAlternate = this.calculationService.flagShowAlternateResults;
    }

    initActionItems() {

        const formData: FormData = this.formDataService.getFormData();

        // item 1
        const item1: ActionItem = {
            title: 'Already have an HSA? Consider consolidating.',
            icon: 'icon-hsa-consolidate.png',
            text: [
                'If you have an HSA at another institution, it\'s easy to transfer or roll over the funds to your '
                + 'Fidelity HSA. By consolidating your HSA, you will benefit from the convenience of '
                + 'having all of your money in a single account.',
                'Note: Rollover and transfer contributions do not count towards the maximum annual contribution amount.',
            ],
        };
        if (formData.currentHSAParticipant === 'Y' && formData.planBalance >= 10000) {
            this.actionItems.push(item1);
        }

        // item 2
        const item2: ActionItem = {
            title: 'Retirement growing near',
            icon: 'icon-retirement-near.png',
            text: [
                'While you may not reach your goal to cover all medical expense needs in retirement, it is beneficial '
                + 'to contribute as much as possible. Remember, any funds you use to pay for qualified medical expenses '
                + 'are tax free. If you have to use money from your 401(k) account, you will have to pay tax on that money, '
                + 'even if it\'s for medical expenses. Plan to maximize your HSA now so that you can save your retirement '
                + 'fund for the things you love to do.'
            ],
        };
        if (formData.currentAge > 50) {
            this.actionItems.push(item2);
        }

        // item 3
        const item3: ActionItem = {
            title: 'Should I spend it or save it',
            icon: 'icon-spend-or-save.png',
            text: [
                'Let it grow. If you can, consider paying for most health care expenses out-of-pocket now '
                + 'while you\'re employed, saving your HSA funds for future needs. Likely this depends '
                + 'on your ability to pay medical expenses from your monthly budget and with a family '
                + 'that can sometimes be challenging. You can use the money now, but consider the power '
                + 'of investing over the long term. Because of this many will spend the money now, but '
                + 'see if you can find a way to leave some money invested for your future. Its yours to '
                + 'keep when you reach retirement and need to pay for Medicare premiums and other expenses '
                + 'on a more limited income. Remember, you can use your HSA funds for non-medical expenses '
                + 'at age 65.'
            ],
        };
        if (formData.currentAge >= 35
            && formData.currentAge < 51
            && formData.coverageType === 'F'
            && this.calculationService.flagSpenderSaver === 'spender') {
            this.actionItems.push(item3);
        }

        // item 4
        const item4: ActionItem = {
            title: 'Your financial future',
            icon: 'icon-financial-future.png',
            text: [
                'There\'s no better time to start saving for the future than now. With decades until '
                + 'retirement you can reap many benefits from investing in an HSA now. Although you '
                + 'may be focused on short term goals such as paying off student loans or saving for '
                + 'a down-payment on a house, it\'s a good idea to start making extra contributions '
                + 'to your HSA. With time on your side, making incremental contributions now, no '
                + 'matter how small, can really add up over time.'
            ],
        };
        if (formData.currentAge >= 18 && formData.currentAge < 35) {
            this.actionItems.push(item4);
        }

        // item 5
        const item5: ActionItem = {
            title: 'How an HSA could work with a 401(k)',
            icon: 'icon-target.png',
            text: ['Here’s a strategy to help maximize your tax savings.'],
            list: [
                'If your employer matches your 401(k) contributions, maximize up to their match amount',
                'Then, it\'s a good idea to fund your HSA to the annual maximum limit.',
                'Any remaining funds can then be applied to your 401(k) up to the maximum limit.',
            ],
        };
        this.actionItems.push(item5);

        // item 6
        const item6: ActionItem = {
            title: 'Did you know your HSA has an investment account option?',
            icon: 'icon-invest.png',
            text: [
                'Once your HSA balance reaches $1,000, you\'re eligible to start investing any portion '
                + 'of the balance above this level in select mutual funds. Watch the Investment video '
                + 'to find out how to get started.'
            ],
            linkURL: 'https://www.brainshark.com/benefitsolutions/vu?pi=zFaz9r6VAz27Kgz0'
        };
        this.actionItems.push(item6);

        // item 7
        const item7: ActionItem = {
            title: 'See how others are using their HSAs',
            icon: 'icon-how-others-using.png',
            text: [
                'Find out how Josh uses his HSA to keep more money in his pocket. View the Long Term Planner video.'
            ],
            linkURL: 'http://healthaccounts.bankofamerica.com/how-others-are-using-their-hsa.shtml',
        };
        if (formData.currentAge >= 18 && formData.currentAge < 35 && formData.coverageType === 'S') {
            this.actionItems.push(item6);
        }

        // item 8
        const item8: ActionItem = {
            title: 'See how others are using their HSAs',
            icon: 'icon-how-others-using.png',
            text: [
                'Find out how Bill uses his HSA to plan for health care in retirement.'
            ],
            linkURL: 'http://healthaccounts.bankofamerica.com/how-others-are-using-their-hsa.shtml',
        };
        if (formData.currentAge > 50) {
            this.actionItems.push(item8);
        }

        // item 9
        const item9: ActionItem = {
            title: 'See how others are using their HSAs',
            icon: 'icon-how-others-using.png',
            text: [
                'Find out how Amy saves money on her health care costs for her family with an HDHP '
                + 'combined with an HSA.'
            ],
            linkURL: 'http://healthaccounts.bankofamerica.com/how-others-are-using-their-hsa.shtml',
        };
        if (formData.currentAge >= 18 && formData.currentAge < 51 && formData.coverageType === 'F') {
            this.actionItems.push(item9);
        }

        // item 10
        const item10: ActionItem = {
            title: 'How much should I put in my HSA?',
            icon: 'icon-contribute.png',
            text: [
                'If you can, try to maximize your contribution each year so you can take advantage of the tax-savings '
                + 'and grow your account balance. However, if your budget is tight we recommend setting a goal to '
                + 'get started. Saving enough to cover your annual deductible may be a good place to start. That '
                + 'way if you incur medical expenses you have money on-hand to cover the bills. Then once you meet '
                + 'that savings goal, you can continue to contribute to build your balance to ensure you will be '
                + 'able to fund medical expenses now or in the future.'
            ],
        };
        this.actionItems.push(item10);

        // make sure we only show 3 items
        this.actionItems = this.actionItems.slice(0, 3);

    }

}
