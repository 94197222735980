import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';
import { Step1Component } from './pages/step1/step1.component';
import { HomeComponent } from './pages/home/home.component';
import { SessionComponent } from './shared/session/session.component';

const appRoutes: Routes = [
    { path: 'step1', component: Step1Component },
    { path: 'session', component: SessionComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
