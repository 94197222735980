import { Injectable } from '@angular/core';

declare const ga: Function;
declare const gtag: Function;

@Injectable()
export class AnalyticsService {

    inputCategory = 'inputs';

    constructor() { }

    /**
     * Send page view to Google Analytics.
     *
     * @param {*} page
     *
     * @memberOf AnalyticsService
     */
    sendPageview(page: any) {
        ga('set', 'page', page);
        ga('send', 'pageview');
    
        // GA4 Tracking call
        gtag('event', 'page_view', {
            page_title: page
        });
    }


    /**
     * Send multiple inputs to Google Analytics events.
     *
     * @param {*} inputs
     *
     * @memberOf AnalyticsService
     */
    sendAllInputs(inputs: any) {
        for (const key in inputs) {
            if (inputs.hasOwnProperty(key)) {
                ga('send', 'event', this.inputCategory, key, inputs[key]);
            }
        }
    }

    /**
     * Send a single key/value input to Google Analytics event.
     *
     * @param {string} key
     * @param {*} value
     *
     * @memberOf AnalyticsService
     */
    sendSingleInput(key: string, value: any) {
        ga('send', 'event', this.inputCategory, key, value);
    }
}
