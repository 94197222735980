import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs/';
import { Observable } from 'rxjs/';

import { PageData } from './pages.model';

@Injectable()
export class PageService {

    pageData: PageData = <PageData>{};
    pageChange = new Subject<PageData>();

    stepIcon1: string;
    stepIcon2: string;
    stepIcon3: string;
    stepIcon4: string;

    /**
     * Creates an instance of PageService.
     * @param {Title} titleService
     *
     * @memberOf PageService
     */
    constructor(private titleService: Title) { }

    /**
     * Sets page data for current page.
     *
     * @param {PageData} page
     *
     * @memberOf PageService
     */
    initPage(page: PageData) {
        // set current page data
        // this.pageData = page;
        this.pageData.currentPage = page.currentPage;
        this.pageData.nextPage = page.nextPage;
        this.pageData.prevPage = page.prevPage;
        this.pageData.stepNum = page.stepNum;
        this.pageData.progress = page.progress;
        this.pageData.firstPage = page.firstPage;
        this.pageData.lastPage = page.lastPage;
        this.pageData.showProgress = page.showProgress;
        this.pageData.stepTitle = page.stepTitle;
        this.pageData.addPrint = page.addPrint;
        this.pageData.showDisclosures = page.hasOwnProperty('showDisclosures') ? page.showDisclosures : true;
        this.pageData.showFootnote = page.hasOwnProperty('showFootnote') ? page.showFootnote : false;
        this.pageData.showNavigation = page.hasOwnProperty('showNavigation') ? page.showNavigation : true;
        // emit the page change event to subscribers
        this.pageChange.next(page);
        // add a dynamic html title if needed
        //this.titleService.setTitle('Fidelity :: HSA Savings Calculator');
    }

    setIcons(stepIcon1: string, stepIcon2: string, stepIcon3: string, stepIcon4: string) {

        this.stepIcon1 = stepIcon1;
        this.stepIcon2 = stepIcon2;
        this.stepIcon3 = stepIcon3;
        this.stepIcon4 = stepIcon4;

    }

    getIcon(iconNum: number) {

        switch (iconNum) {
            case 1:
                return this.stepIcon1;
              break;
              
            case 2:
                return this.stepIcon2;
              break;

            case 3:
                return this.stepIcon3;
                break;

            case 4:
                return this.stepIcon4;
                break;

            default:
              break;
          }

    }

}
