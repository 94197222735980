import { NgModule } from '@angular/core';
import { Routes, Router, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { Step1Component } from './step1/step1.component';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { Step4Component } from './step4/step4.component';
import { SummaryComponent } from './summary/summary.component';
import { ResultsComponent } from './results/results.component';
import { SessionComponent } from '../shared/session/session.component';


const pagesRoutes: Routes = [
    { path: '', redirectTo: 'step1' },
    { path: '/', redirectTo: 'step1' },
    // { path: '', component: Step1Component },
    { path: 'step1', component: Step1Component },
    // { path: 'home', component: HomeComponent },
    // { path: 'step1', component: Step1Component },
    { path: 'step2', component: Step2Component },
    { path: 'step3', component: Step3Component },
    { path: 'step4', component: Step4Component },
    { path: 'summary', component: SummaryComponent },
    { path: 'results', component: ResultsComponent },
    { path: '**', redirectTo: '/' },
];

@NgModule({
    imports: [RouterModule.forRoot(pagesRoutes)],
    exports: [RouterModule],
})
export class PagesRoutingModule { }
