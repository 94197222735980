import { Component, OnInit, Input } from '@angular/core';
import { ActionItem } from './action-item.type';

@Component({
    selector: 'app-action-item',
    templateUrl: './action-item.component.html',
    styleUrls: ['./action-item.component.css']
})
export class ActionItemComponent implements OnInit {

    @Input() actionItem: ActionItem;
    @Input() index: number;

    hasLink: boolean;

    constructor() { }

    ngOnInit() {
        this.hasLink = this.actionItem.linkURL !== undefined;
    }

    navLink(url: string) {
        if (url !== undefined) {
            window.open(url);
        }
    }

}
