import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AnalyticsService } from '../shared/analytics/analytics.service';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit, OnDestroy {

    private pageSubscription: any;

    /**
     * Creates an instance of PagesComponent.
     * @param {Router} router
     *
     * @memberOf PagesComponent
     */
    constructor(private router: Router,
                private analyticsService: AnalyticsService) { }

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf PagesComponent
     */
    ngOnInit() {
        // scroll to top of the page on router change
        // do this only at NavigationEnd
        this.pageSubscription = this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            // scroll to top of page
            window.scrollTo(0, 0);
            // send page to GA
            this.analyticsService.sendPageview(evt.urlAfterRedirects);
        });
    }

    /**
     * Destroy actions when Angular is done with component.
     *
     * @memberOf PagesComponent
     */
    ngOnDestroy() {
        this.pageSubscription.unsubscribe();
    }

}
