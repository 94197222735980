import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';



import { PageService } from '../pages.service';
import { PageData } from '../pages.model';
import { UtilService } from '../../shared/services/util.service';
import { FormDataService } from '../shared/form-data/form-data.service';
import { FormData } from '../shared/form-data/form-data.model';
import { CalculationsService } from '../shared/calculations/calculations.service';
import { CONSTANTS } from '../shared/calculations/calc-constants';
import { ValidationService } from '../shared/form-validations/validation.service';

declare var Slider: any;
import * as $ from 'jquery';

@Component({
    selector: 'app-step1',
    templateUrl: './step1.component.html',
    styleUrls: ['./step1.component.css']
})
export class Step1Component implements OnInit, OnDestroy {

    pageData: PageData;

    formStep1: FormGroup;
    formData: FormData;
    formErrors: any;
    sliderROR: any;
    stateTax: number;
    state: string;

    currentAgeOptions: Array<number> = [];
    retireAgeOptions: Array<number> = [];

    fedTaxOptions: Array<number> = [];
    //stateTaxOptions: Array<number> = [];
    // stateTaxOptions:String[] =  new Array(51) ;
    stateTaxOptions:Object;
    


    showPlanBalance = false;
    planBalanceValidations: Array<any> = [];

    catchupEligible: boolean;

    calloutTitle: string;

    private formSubscription: any;

    /**
     * Creates an instance of Step1Component.
     * @param {PageService} pageService
     * @param {FormBuilder} formBuilder
     * @param {FormDataService} formDataService
     * @param {CalculationsService} calculationService
     * @param {ValidationService} validationService
     *
     * @memberOf Step1Component
     */
    constructor(private pageService: PageService,
        private formBuilder: FormBuilder,
        private formDataService: FormDataService,
        private calculationService: CalculationsService,
        private utilService: UtilService,
        private validationService: ValidationService) { }

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf Step1Component
     */
    ngOnInit() {
        this.initPageData();
        this.initFormData();
        this.stateTax = this.formDataService.getValue('stateTax');
        this.state = this.formDataService.getValue('state');
        this.buildCurrentAgeOptions();
        this.buildRetirementAgeOptions(this.validationService.minRetAge);
        this.togglePlanBalance(this.formData.currentHSAParticipant);
        this.buildFedTaxOptions();
        this.buildStateTaxOptions();
        this.initSliders();
        this.setCatchupEligible(this.formData.currentAge);

//         this.formStep1.controls['planBalance'].setValue(this.utilService.fmt(this.formStep1.controls['planBalance']));
// console.log(this.utilService.fmt(this.formStep1.controls['planBalance']));


        //this.initSliders();
    }
    // ngAfterViewInit() {
    //     this.initSliders();
    // }

    /**
     * Destroy actions when Angular is done with component.
     *
     * @memberOf Step1Component
     */
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }

    closeTooltip() {
        // alert("OK");
        // let x = this;
        // var theClass = $(this).attr('class');
        // $('bs-tooltip-container.tooltip').hide();
    }

    calc() {

        // console.log("calc()");
        // calculate
        // this.recalculateChart();

        // set flag to show alternate on results page
        this.calculationService.setShowAlternateResultsFlag();

        // calculate tax savings for alt contribs
        this.calculationService.calcTaxSavingsAlt();
        
    }

    initSliders() {
        // create slider instance
        this.sliderROR = new Slider('#ror', {
            min: this.validationService.minROR,
            max: this.validationService.maxROR,
            step: 1,
            handle: 'round',
            labelledby: "rorlabel",
            value: this.formDataService.getValue('ror'),
            tooltip: 'always',
            formatter: function(value) {
                return value + '%';
            }
        });

        // slider change event - set value manually
        // this does not get picked up by valueChanges subscription
        const that = this;
        this.sliderROR.on('change', function(e) {
            that.sliderROR.setValue(e.newValue);
            that.formDataService.setValue('ror', e.newValue);
        });
    }


    /**
     * Inits page specific data and sends this data to the page service.
     *
     * @memberOf Step1Component
     */
    initPageData() {
        this.pageData = {
            currentPage: 'step1',
            nextPage: 'step2',
            prevPage: '',
            stepNum: 1,
            progress: 25,
            firstPage: true,
            lastPage: false,
            showProgress: true,
            stepTitle: 'About you',
            stepIcon: ''
        };
        this.pageService.initPage(this.pageData);
    }

    /**
     * Init data needed for form.
     *
     * @memberOf Step1Component
     */
    initFormData() {
        this.formData = this.formDataService.getFormData();
        this.formErrors = this.validationService.validationErrors;
        this.buildForm();
    }

    /**
     * Use FormBuilder to create form.
     * Subscribe to form value changes and validate.
     * Save data to form data service if valid.
     * Calculate values as needed.
     *
     * @memberOf Step1Component
     */
    buildForm() {
        // build form
        this.formStep1 = this.formBuilder.group({
            coverageType: [this.formData.coverageType, Validators.required],
            currentAge: [this.formData.currentAge, Validators.required],
            retirementAge: [this.formData.retirementAge, Validators.required],
            currentHSAParticipant: [this.formData.currentHSAParticipant, Validators.required],
            planBalance: [this.formData.planBalance, this.planBalanceValidations],
            fedTax: [this.formData.fedTax, Validators.required],
            stateTax: [this.formData.stateTax, Validators.required],
            state: [this.formData.state, Validators.required],
            ror: [this.formData.ror, Validators.required]

        });

        // set current form data in formDataService
        this.formDataService.currentFormGroup = this.formStep1;

        // subscribe to form value changes
        this.formSubscription = this.formStep1.valueChanges.subscribe(data => {
            this.formErrors = this.validationService.validateFormData(data, this.formStep1);
            if (this.formStep1.valid) {
                this.formDataService.setFormData(data);
            }
            this.calculationService.calcHSAMax(data.currentAge, data.coverageType);
            this.calculationService.calcDefaultHSAContribSpend('force');
            this.calculationService.calcRetirementNeed(data.coverageType);
            this.calculationService.calcYearsUntilRetirement(data.currentAge, data.retirementAge);
        });
    }

    /**
     * Build the options for the current age select.
     * Confined by the minAge/maxAge class properties.
     *
     * @memberOf Step1Component
     */
    buildCurrentAgeOptions() {
        const min = this.validationService.minCurrentAge;
        const max = this.validationService.maxCurrentAge;
        for (let i = min; i <= max; i++) {
            this.currentAgeOptions.push(i);
        }
    }

    /**
     * Build the options for the retirement age select.
     * Confined by a age param and maxRetAge class property.
     *
     * @param {number} age
     *
     * @memberOf Step1Component
     */
    buildRetirementAgeOptions(age: number) {
        // build retirement age options
        const min: number = age;
        const max: number = this.validationService.maxRetAge;
        this.retireAgeOptions = [];
        for (let i = min; i <= max; i++) {
            this.retireAgeOptions.push(i);
        }

        // update retirement age if selected current age is greater
        const minAge = this.formStep1.controls['retirementAge'].value;
        if (age > minAge) {
            this.formStep1.patchValue({ retirementAge: age });
        }
    }


    /**
  * Build federal tax options from array.
  *
  * @memberOf Step2Component
  */
    buildFedTaxOptions() {
        this.fedTaxOptions = this.validationService.fedTaxOptions;
    }

    /**
     * Build state tax options from range.
     *
     * @memberOf Step2Component
     */
    buildStateTaxOptions() {

        const min: number = this.validationService.stateTaxMin;
        const max: number = this.validationService.stateTaxMax;
     
        this.stateTaxOptions = [ 
            {state: 'AL', rate: 5.00},
            {state: 'AK', rate: 0.00},
            {state: 'AZ', rate: 2.50},
            {state: 'AR', rate: 4.90},
            {state: 'CA', rate: 6.00},
            {state: 'CO', rate: 4.40},
            {state: 'CT', rate: 5.00},
            {state: 'DC', rate: 8.50},
            {state: 'DE', rate: 6.60},
            {state: 'FL', rate: 0.00},
            {state: 'GA', rate: 5.75},
            {state: 'HI', rate: 7.90},
            {state: 'ID', rate: 5.80},
            {state: 'IL', rate: 4.95},
            {state: 'IN', rate: 3.15},
            {state: 'IA', rate: 5.70},
            {state: 'KS', rate: 5.70},
            {state: 'KY', rate: 4.50},
            {state: 'LA', rate: 3.50},
            {state: 'ME', rate: 6.75},
            {state: 'MD', rate: 4.75},
            {state: 'MA', rate: 5.00},
            {state: 'MI', rate: 4.25},
            {state: 'MN', rate: 6.80},
            {state: 'MS', rate: 5.00},
            {state: 'MO', rate: 4.95},
            {state: 'MT', rate: 6.75},
            {state: 'NE', rate: 6.64},
            {state: 'NV', rate: 0.00},
            {state: 'NH', rate: 0.00},
            {state: 'NJ', rate: 3.50},
            {state: 'NM', rate: 4.90},
            {state: 'NY', rate: 5.50},
            {state: 'NC', rate: 4.75},
            {state: 'ND', rate: 2.00},
            {state: 'OH', rate: 3.22},
            {state: 'OK', rate: 4.75},
            {state: 'OR', rate: 8.75},
            {state: 'PA', rate: 3.07},
            {state: 'RI', rate: 4.75},
            {state: 'SC', rate: 6.50},
            {state: 'SD', rate: 0.00},
            {state: 'TN', rate: 0.00},
            {state: 'TX', rate: 0.00},
            {state: 'UT', rate: 4.85},
            {state: 'VT', rate: 6.60},
            {state: 'VA', rate: 5.75},
            {state: 'WA', rate: 0.00},
            {state: 'WV', rate: 6.50},
            {state: 'WI', rate: 5.30},
            {state: 'WY', rate: 0.00}
            

            // {state: 'AL', rate: 4.00},
            // {state: 'AK', rate: 0.00},
            // {state: 'AZ', rate: 5.60},
            // {state: 'AR', rate: 6.50},
            // {state: 'CA', rate: 7.25},
            // {state: 'CO', rate: 2.90},
            // {state: 'CT', rate: 6.35},
            // {state: 'DE', rate: 0.00},
            // {state: 'DC', rate: 6.00},
            // {state: 'FL', rate: 6.00},
            // {state: 'GA', rate: 4.00},
            // {state: 'HI', rate: 4.00},
            // {state: 'ID', rate: 6.00},
            // {state: 'IL', rate: 6.25},
            // {state: 'IN', rate: 7.00},
            // {state: 'IA', rate: 6.00},
            // {state: 'KS', rate: 6.50},
            // {state: 'KY', rate: 6.00},
            // {state: 'LA', rate: 4.45},
            // {state: 'ME', rate: 5.50},
            // {state: 'MD', rate: 6.00},
            // {state: 'MA', rate: 6.25},
            // {state: 'MI', rate: 6.00},
            // {state: 'MN', rate: 6.875},
            // {state: 'MS', rate: 7.00},
            // {state: 'MO', rate: 4.225},
            // {state: 'MT', rate: 0.00},
            // {state: 'NE', rate: 5.50},
            // {state: 'NV', rate: 6.85},
            // {state: 'NH', rate: 0.00},
            // {state: 'NJ', rate: 6.625},
            // {state: 'NM', rate: 5.00},
            // {state: 'NY', rate: 4.00},
            // {state: 'NC', rate: 4.75},
            // {state: 'ND', rate: 5.00},
            // {state: 'OH', rate: 5.75},
            // {state: 'OK', rate: 4.50},
            // {state: 'OR', rate: 0.00},
            // {state: 'PA', rate: 6.00},
            // {state: 'RI', rate: 7.00},
            // {state: 'SC', rate: 6.00},
            // {state: 'SD', rate: 4.50},
            // {state: 'TN', rate: 7.00},
            // {state: 'TX', rate: 6.25},
            // {state: 'UT', rate: 6.10},
            // {state: 'VT', rate: 6.00},
            // {state: 'VA', rate: 5.30},
            // {state: 'WA', rate: 6.50},
            // {state: 'WV', rate: 6.00},
            // {state: 'WI', rate: 5.00},
            // {state: 'WY', rate: 4.00}
        
        ];
	
        // for (let i = min; i <= max; i++) {
        //     //this.stateTaxOptions.push(i);
        // }
    }

    setStateTax(e) {

        this.formDataService.setValue('stateTax', e.rate);
        this.formDataService.setValue('state', e.state);
        this.formStep1.patchValue({ stateTax: e.rate });
        this.formStep1.patchValue({ state: e.state });
        this.calculationService.calcTaxSavings();

    }


    /**
     * Toggle plan balance field.
     *
     * @param {string} currentHSAParticipant
     *
     * @memberOf Step2Component
     */
    togglePlanBalance(currentHSAParticipant: string) {
        // plan balance form control
        const control = this.formStep1.get('planBalance');

        // if user has a balance, show the input and set
        // form validators. If not, hide input and
        // remove validators.
        if (currentHSAParticipant === 'Y') {
            this.showPlanBalance = true;
            control.setValidators(this.planBalanceValidations);
            if (control.value === null || control.value === 0) {
                control.setValue('');
            }
        } else {
            this.showPlanBalance = false;
            control.setValidators(null);
        }

        // update control value and validity
        control.updateValueAndValidity();
    }



    /**
     * Rebuild retirement age options based on input current age value.
     * Calculate catchup eligibility based on age.
     *
     * @param {number} age
     *
     * @memberOf Step1Component
     */
    // coverageTypeChange(mytype: number) {
    //     const newAge = Math.max(Number(age) + 1, this.validationService.minRetAge);
    //     this.buildRetirementAgeOptions(newAge);
    //     this.co
    // }


    currentAgeChange(age: number) {
        const newAge = Math.max(Number(age) + 1, this.validationService.minRetAge);
        this.buildRetirementAgeOptions(newAge);
        this.setCatchupEligible(age);
    }



    /**
     * Determine catchup eligiblity based on age param.
     *
     * @param {number} age
     *
     * @memberOf Step1Component
     */
    setCatchupEligible(age: number) {
        this.calculationService.calcCatchupEligible(age);
        this.catchupEligible = this.calculationService.catchupEligible;
    }

    /**
     * Get CONSTANTS for use in HTML.
     *
     * @readonly
     *
     * @memberOf Step1Component
     */
    get constants() {
        return CONSTANTS;
    }
}
