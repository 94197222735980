import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormData } from './form-data.model';

import { SessionService } from '../../../shared/session/session.service';

@Injectable()
export class FormDataService {

    private defaults: FormData;
    private formData: FormData;

    currentFormGroup: FormGroup;

    saveToSession = true;

    /**
     * Creates an instance of FormDataService.
     * @param {SessionService} sessionService
     *
     * @memberOf FormDataService
     */
    constructor(private sessionService: SessionService) {
        this.initFormData();
    }

    /**
     * Init form data by starting with default values and adding stored values.
     *
     * @memberOf FormDataService
     */
    initFormData() {

        // init default form data
        this.defaults = new FormData();

        // start with new form data (defaults)
        let data: FormData = this.defaults;

        // if we are using session get those values
        if (this.saveToSession) {
            // get session form values
            const sessionData = this.sessionService.getFormInputs();
            // session storage stores everything as strings - convert to correct types
            data = this.setFormDataTypes(data, sessionData);
        }

        // set data to formData property
        this.formData = data;

    }

    /**
     * SessionStorage stores everything as strings, so we need to convert back
     * to its default type. Iterate over default form data to get types and
     * convert as needed.
     *
     * @param {FormData} data
     * @param {*} sessionData
     * @returns {FormData} data
     *
     * @memberOf FormDataService
     */
    setFormDataTypes(data: FormData, sessionData: any) {
        Object.keys(data).forEach(key => {
            const sessionValue = sessionData[key];
            if (sessionValue !== undefined && sessionValue !== null && sessionValue !== 'null') {
                const type = typeof data[key];
                switch (type) {
                    case 'number':
                        data[key] = Number(sessionValue);
                        break;
                    case 'string':
                        data[key] = sessionValue;
                        break;
                    default:
                        data[key] = sessionValue;
                        break;
                }
            }
        });
        return data;
    }

    /**
     * Get default form data.
     *
     * @returns {*}
     *
     * @memberOf FormDataService
     */
    getDefaults() {
        return this.defaults;
    }

    /**
     * Get current form data.
     *
     * @returns {*}
     *
     * @memberOf FormDataService
     */
    getFormData() {
        return this.formData;
    }

    /**
     * Set form data.
     *
     * @param {*} data
     *
     * @memberOf FormDataService
     */
    setFormData(data: any) {
        Object.keys(data).forEach(key => {
            this.formData[key] = data[key];
        });
        if (this.saveToSession) {
            this.sessionService.saveFormInputs(data);
        }
    }

    /**
     * Set specific key/value in form data.
     *
     * @param {string} key
     * @param {*} value
     *
     * @memberOf FormDataService
     */
    setValue(key: string, value: any) {
        this.formData[key] = value;
        if (this.saveToSession) {
            this.sessionService.saveSessionItem(key, value);
        }
    }

    /**
     * Get specific value from form key.
     *
     * @param {string} key
     * @returns {*}
     *
     * @memberOf FormDataService
     */
    getValue(key: string) {
        return this.formData[key];
    }

}
