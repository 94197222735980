import { Component, OnInit, Input } from '@angular/core';

import { SummaryItem } from './summary-item.type';

@Component({
    selector: 'app-summary-item',
    templateUrl: './summary-item.component.html',
    styleUrls: ['./summary-item.component.css']
})
export class SummaryItemComponent implements OnInit {
    @Input() summaryItem: SummaryItem;
    constructor() { }

    ngOnInit() {
    }

}
