import { Component, OnInit, OnDestroy } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { PageService } from '../pages.service';
import { PageData } from '../pages.model';

import { FormDataService } from '../shared/form-data/form-data.service';
import { FormData } from '../shared/form-data/form-data.model';

import { SessionService } from '../../shared/session/session.service';
import { CalculationsService } from '../shared/calculations/calculations.service';
import { ValidationService } from '../shared/form-validations/validation.service';
import { CONSTANTS } from '../shared/calculations/calc-constants';

declare var Slider: any;

@Component({
    selector: 'app-step4',
    templateUrl: './step4.component.html',
    styleUrls: ['./step4.component.css'],
})
export class Step4Component implements OnInit, OnDestroy {

    pageData: PageData;

    formStep4: FormGroup;
    formData: FormData;
    formDefaults: FormData;
    formErrors: any;

    validationMessages: any;

    sliderHSAContrib: any;
    sliderHSASpend: any;

    formSubscription: any;

    /**
     * Creates an instance of Step4Component.
     * @param {PageService} pageService
     * @param {SessionService} sessionService
     * @param {FormBuilder} formBuilder
     * @param {FormDataService} formDataService
     * @param {CalculationsService} calculationService
     * @param {ValidationService} validationService
     * @param {CurrencyPipe} currencyPipe
     *
     * @memberOf Step4Component
     */
    constructor(private pageService: PageService,
                private sessionService: SessionService,
                private formBuilder: FormBuilder,
                private formDataService: FormDataService,
                private calculationService: CalculationsService,
                public validationService: ValidationService,
                private currencyPipe: CurrencyPipe) {
    }

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf Step4Component
     */
    ngOnInit() {
        this.initPageData();
        this.initFormData();
        this.initSliders();
    }

    /**
     * Destroy actions when Angular is done with component.
     *
     * @memberOf Step4Component
     */
    ngOnDestroy() {
        this.formSubscription.unsubscribe();
    }

    /**
     * Inits page specific data and sends this data to the page service.
     *
     * @memberOf Step4Component
     */
    initPageData() {
        this.pageData = {
            currentPage: 'step4',
            nextPage: 'summary',
            prevPage: 'step3',
            stepNum: 4,
            progress: 100,
            firstPage: false,
            lastPage: false,
            showProgress: true,
            stepTitle: 'Contributions and expenses',
            stepIcon: ''
        };
        this.pageService.initPage(this.pageData);
    }

    /**
     * Init data needed for form.
     *
     * @memberOf Step4Component
     */
    initFormData() {
        this.formDefaults = this.formDataService.getDefaults();
        this.formData = this.formDataService.getFormData();
        this.formErrors = this.validationService.validationErrors;
        this.buildForm();
    }

    /**
     * Use FormBuilder to create form.
     * Subscribe to form value changes and validate.
     * Save data to form data service if valid.
     * Calculate values as needed.
     *
     * @memberOf Step4Component
     */
    buildForm() {
        // build step 4 form
        this.formStep4 = this.formBuilder.group({
            hsaContrib: [this.formData['hsaContrib'], Validators.required],
            hsaSpend: [this.formData['hsaSpend'], Validators.required],
        });

        // set current form data in formDataService
        this.formDataService.currentFormGroup = this.formStep4;

        // subscribe to step 4 form changes
        this.formSubscription = this.formStep4.valueChanges.subscribe(data => {
            this.formErrors = this.validationService.validateFormData(data, this.formStep4);
            if (this.formStep4.valid) {
                this.formDataService.setFormData(data);
            }
        });
    }

    /**
     * Handle HSA contrib slider changes.
     * Set max HSA spend value to the HSA contrib value
     * to make sure user does not spend more than they contribute.
     *
     * @param {number} value
     *
     * @memberOf Step4Component
     */
    hsaContribChange(value: number) {
        const oop = Number(this.formDataService.getValue('hsaOOP'));
        this.validationService.hsaMaxSpend = Math.min(value, oop);
        return this.validationService.hsaMaxSpend;
    }

    /**
     * Init HSA sliders.
     *
     * @memberOf Step4Component
     */
    initSliders() {

        // update validation service with hsa max spend and contrib amounts
        this.validationService.hsaMaxContrib = this.calculationService.hsaMax;
        this.validationService.hsaMaxSpend = Math.min(
            Number(this.formDataService.getValue('hsaContrib')),
            Number(this.formDataService.getValue('hsaOOP'))
        );

        const that = this;

        // HSA contribution slider
        this.sliderHSAContrib = new Slider('#hsaContrib', {
            min: this.validationService.hsaMinContrib,
            max: this.validationService.hsaMaxContrib,
            step: CONSTANTS.SLIDER_STEP,
            handle: 'square',
            value: this.formDataService.getValue('hsaContrib'),
            tooltip: 'always',
            formatter: function(value) {
                return that.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0');
            }
        });

        // HSA spend slider
        this.sliderHSASpend = new Slider('#hsaSpend', {
            min: this.validationService.hsaMinSpend,
            max: this.validationService.hsaMaxSpend,
            step: CONSTANTS.SLIDER_STEP,
            handle: 'square',
            value: this.formDataService.getValue('hsaSpend'),
            tooltip: 'always',
            formatter: function(value) {
                return that.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0');
            }
        });

        // HSA contribution slider changes
        // Set values manually (not picked up by formSubscription).
        // Update spend slider values as well depending on contrib amount.
        // User cannot spend more than they contrib.
        // Update flags for max contributor and min spender.
        // Recalculate tax savings.
        this.sliderHSAContrib.on('change', function(e) {
            // contribution value from slider change event
            const contribVal = e.newValue;
            // spend value from other slider method
            const spendVal = that.sliderHSASpend.getValue();
            // set maximum spend value
            const maxHSASpend = that.hsaContribChange(contribVal);
            // set value of contrib slider
            that.sliderHSAContrib.setValue(contribVal, true, true);
            that.formDataService.setValue('hsaContrib', contribVal);
            // set new max for spend slider equal to value of contrib slider
            that.sliderHSASpend.setAttribute('max', maxHSASpend);
            // if we are spending more than contributing, correct that
            let spendUse = 0;
            if (spendVal > contribVal) {
                spendUse = contribVal;
            } else {
                spendUse = spendVal;
            }
            that.sliderHSASpend.setValue(spendUse);
            that.sliderHSASpend.setAttribute('value', spendUse);
            that.formDataService.setValue('hsaSpend', spendUse);
            // refresh spend slider with new max and possible new value
            // that.sliderHSASpend.refresh();
            // calculate alternate values
            that.calculationService.calcAlternateContrib(contribVal, 'force');
            that.calculationService.calcAlternateSpend(spendUse, 'force');
            // set calc flag for max contributor
            that.calculationService.setMaxContributor(contribVal);
            // set calc flag for min spender
            that.calculationService.setMinSpender(spendUse);
            // set calc flag for showing alt scenario
            that.calculationService.setShowAlternateSummaryFlag();
            that.calculationService.setShowAlternateResultsFlag();
            // calc tax savings
            that.calculationService.calcTaxSavings();
            that.calculationService.calcTaxSavingsAlt();
            // set spender/saver flag in calc service
            that.calculationService.setSpenderSaverFlag(contribVal, spendUse);
        });

        // HSA spend slider changes
        // Manually set values (formSubscription does not pick these up)
        // Update flags for min spender
        this.sliderHSASpend.on('change', function(e) {
            const spendVal = e.newValue;
            const contribVal = that.sliderHSAContrib.getValue();
            that.sliderHSASpend.setValue(spendVal, true, true);
            that.formDataService.setValue('hsaSpend', spendVal);
            // calculate alternate pct
            that.calculationService.calcAlternateSpend(spendVal, 'force');
            // set calc flag for min spender
            that.calculationService.setMinSpender(spendVal);
            // set calc flag for showing alt scenario
            that.calculationService.setShowAlternateSummaryFlag();
            that.calculationService.setShowAlternateResultsFlag();
            // set spender/saver flag in calc service
            that.calculationService.setSpenderSaverFlag(contribVal, spendVal);
        });
    }

}
