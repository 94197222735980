import { Component, OnInit } from '@angular/core';

import { SessionService } from './session.service';
import { PageService } from '../../pages/pages.service';

@Component({
    selector: 'app-session',
    templateUrl: './session.component.html',
    styleUrls: ['./session.component.css']
})
export class SessionComponent implements OnInit {

    constructor(private sessionService: SessionService,
                private pageService: PageService) { }

    ngOnInit() {
        this.sessionService.clearSession();
        this.initPageData();
    }

    /**
     * Inits page specific data and sends this data to the page service.
     *
     * @memberOf SessionComponent
     */
    initPageData() {
        const pageData = {
            currentPage: 'session',
            nextPage: '',
            prevPage: '',
            progress: 0,
            stepNum: 0,
            firstPage: true,
            lastPage: true,
            showProgress: false,
            stepTitle: '',
            showDisclosures: false,
            stepIcon: ''
        };
        this.pageService.initPage(pageData);
    }
}
