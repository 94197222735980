import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { PageService } from '../../pages.service';
import { PageData } from '../../pages.model';
import { ValidationService } from '../form-validations/validation.service';
import { FormDataService } from '../form-data/form-data.service';
import { AnalyticsService } from '../../../shared/analytics/analytics.service';
import { ApiService } from '../../../shared/services/api.service';  //../../../../app/shared/services/api.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { Subject } from 'rxjs';
// import { takeUntil } from 'rxjs/operators';
// import { interpolation1 } from '@angular/common/';


import { ReportDataService } from '../../../shared/models/reportdata.service';
import { ReportData } from '../../../shared/models/reportdata.model';
//import { ValueConverter } from '@angular/compiler/src/render3/view/template';


declare const ga: Function;
declare const gtag: Function;

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
    pageData: PageData;
    reportData: ReportData;
    formErrors: any;
    errorArray: Array<string> = [];
    @ViewChild('errorModal', { static: true }) public modal: ModalDirective;
    @ViewChild('pdfModal', { static: true }) public modal2: ModalDirective;

    myURL: any;
    stepIcon1: string;
    stepIcon2: string;
    stepIcon3: string;
    stepIcon4: string;

    /**
     * Creates an instance of NavigationComponent.
     * @param {PageService} pageService
     * @param {Router} router
     * @param {ValidationService} validationService
     *
     * @memberOf NavigationComponent
     */
    constructor(private pageService: PageService,
        private router: Router,
        private reportDataService: ReportDataService,
        private apiService: ApiService,
        private validationService: ValidationService,
        private formDataService: FormDataService,
        private analyticsService: AnalyticsService) { }

    /**
     * Init actions when Angular is done creating the component.
     *
     * @memberOf NavigationComponent
     */
    ngOnInit() {
        this.pageData = this.pageService.pageData;

        this.stepIcon1 = "check.png";
        this.stepIcon2 = "x-out.png";
        this.stepIcon3 = "x-out.png";
        this.stepIcon4 = "x-out.png";
        this.pageService.setIcons(this.stepIcon1, this.stepIcon2, this.stepIcon3, this.stepIcon4);
    }
  

    /**
     * Navigate to the previous page.
     *
     * @memberOf NavigationComponent
     */
    navPrev() {
        this.router.navigate(['/' + this.pageData.prevPage]);

        switch (this.pageData.stepNum-1) {
            case 1:
                this.stepIcon1 = "check.png";

                this.stepIcon2 = "check.png";
              break;
              
            case 2:
                this.stepIcon2 = "step2.png";
              
                this.stepIcon3 = "check.png";
              break;

            case 3:
                this.stepIcon3 = "step3.png";
               
                this.stepIcon4 = "check.png";
                break;

            default:
              break;
          }

          this.pageService.setIcons(this.stepIcon1, this.stepIcon2, this.stepIcon3, this.stepIcon4);

    }

    /**
     * Navigate to the next page.
     * First we validate the current form to make sure we can move to next page.
     *
     * @memberOf NavigationComponent
     */
    navNext() {

        // validate form (first must mark all as dirty)
        const currentForm = this.formDataService.currentFormGroup;
        for (const field in currentForm.controls) {
            if (currentForm.controls.hasOwnProperty(field)) {
                const control = currentForm.get(field);
                control.markAsDirty();
            }
        };
        this.validationService.validateFormData(currentForm.value, currentForm);

        // navigate to the next page if form is valid
        // if invalid show error modal
        if (this.validationService.validForm) {
            this.analyticsService.sendAllInputs(currentForm.value);
            this.router.navigate(['/' + this.pageData.nextPage]);
        } else {
            this.errorArray = this.validationService.validationArray;
            this.modal.show();
        }
        this.stepIcon1 = "check.png";
        switch (this.pageData.stepNum+1) {
            case 1:
                this.stepIcon1 = "check.png";
              break;

            case 2:
                this.stepIcon2 = "step2.png";
              
                this.stepIcon3 = "x-out.png";
                this.stepIcon4 = "x-out.png";
              break;

            case 3:
                this.stepIcon3 = "step3.png";
               
                this.stepIcon2 = "check.png";
                this.stepIcon4 = "x-out.png";
                break;

            case 4:
                this.stepIcon4 = "step4.png";

                this.stepIcon2 = "check.png";
                this.stepIcon3 = "check.png";
                break;

            default:
              break;
          }

          this.pageService.setIcons(this.stepIcon1, this.stepIcon2, this.stepIcon3, this.stepIcon4);

    }


  
    backToFdelity() {

        gtag('event', 'page_view', {
            page_title: '/Back to Fidelity'
        });
    
        window.open("https://www.fidelity.com/go/hsa/why-hsa?ccsource=calculator_web_redirect_2023", "_blank");
    }



    pdfModalOpen() {

      
    
        this.modal2.show();
    }
    pdfModalClose() {
        this.modal2.hide();
    }
    pdfPrint() {

        var jsonData = new Array();

        var mydate = new Date();
        var filename =
            'HSA-' + Math.floor(Math.random() * 1000000) + '-' + mydate.getTime()

        this.reportDataService.reportData.client_name = (<HTMLInputElement>document.getElementById("client_fname")).value + " " + (<HTMLInputElement>document.getElementById("client_lname")).value;
        this.reportDataService.reportData.advisor_name = (<HTMLInputElement>document.getElementById('advisor_name')).value;
        this.reportDataService.reportData.advisor_company = (<HTMLInputElement>document.getElementById("advisor_company")).value;
        this.reportDataService.reportData.advisor_phone = (<HTMLInputElement>document.getElementById("advisor_phone")).value;
        this.reportDataService.reportData.advisor_email = (<HTMLInputElement>document.getElementById("advisor_email")).value;

        // console.log("pdfPrint, filename: " + filename + ", rptIntro1: " + this.reportDataService.reportData.rptIntro1) ;

        jsonData.push({
            "rptIntro1": this.reportDataService.reportData.rptIntro1,
            "rptIntro2": this.reportDataService.reportData.rptIntro2,
            "summaryFootnote": this.reportDataService.reportData.summaryFootnote,
            "client_name": this.reportDataService.reportData.client_name,
            "advisor_name": this.reportDataService.reportData.advisor_name,
            "advisor_company": this.reportDataService.reportData.advisor_company,
            "advisor_phone": this.reportDataService.reportData.advisor_phone,
            "advisor_email": this.reportDataService.reportData.advisor_email,
            "projRetBalance": this.reportDataService.reportData.projRetBalance,
            "altRetBalance": this.reportDataService.reportData.altRetBalance,
            "hsaContrib": this.reportDataService.reportData.hsaContrib,
            "hsaOOP": this.reportDataService.reportData.hsaOOP,
            "hsaSpendAlt": this.reportDataService.reportData.hsaSpendAlt,
            "hsaContribAlt": this.reportDataService.reportData.hsaContribAlt,
            "taxSavings": this.reportDataService.reportData.taxSavings,
            "cumulativeTaxSavings": this.reportDataService.reportData.cumulativeTaxSavings,
            "retirementAge": this.reportDataService.reportData.retirementAge,
            "fedTax": this.reportDataService.reportData.fedTax,
            "stateTax": this.reportDataService.reportData.stateTax,
            "ror": this.reportDataService.reportData.ror,
            "filename": filename
        });

        this.apiService.getPDF(jsonData).subscribe(
            response => {


                let url = location.origin;
                // // console.log("url: " + url + ", filename: " + filename);
                if (url.indexOf(".cloud") == -1) {
                    // // console.log("1");
                    //let url = "http://fidelity-hsa.local:8073/pdf/" + filename + ".pdf";
                    let url = "http://fidhsac/report/pdf/" + filename + ".pdf";
                    //// console.log( 'calling window.open with URL: ' + url)
                    window.open(url, '_blank');
                } else {

                    if (url.indexOf("fidelity-hsacalculator-uat") != -1) {
                        window.open("https://fidelity-hsacalculator-uat.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                    } else  if (url.indexOf("fidelity-hsacalculator-kc") != -1 ) {
                        window.open("https://fidelity-hsacalculator-kc.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                    } else  if (url.indexOf("fidelity-hsacalculator-stl") != -1 ) {
                        window.open("https://fidelity-hsacalculator-stl.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                    } else  if (url.indexOf("fidelity-hsacalculator") != -1 ) {
                        window.open("https://fidelity-hsacalculator.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                    } else {
                        window.open("https://fidelity-hsacalculator.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                    }


                    // // // console.log("2");
                    // if (url.indexOf("fidelity-hsacalculator") != -1 ) {
                    //     if (url.indexOf("uat") != -1 || url.indexOf("qa") != -1) {
                    //         // console.log("3");
                    //         window.open("https://fidelity-hsacalculator-uat.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                        
                    //     } else {
                    //         // console.log("4");
                    //         window.open("https://fidelity-hsacalculator.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                    //     }
                    // } else  if (url.indexOf("demo4-uat.ssnc.cloud") != -1 ) {
                    //     // console.log("5");
                    //     if (url.indexOf("uat") != -1 || url.indexOf("qa") != -1) {
                    //         // console.log("6");
                    //         window.open("https://demo4-uat.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                        
                    //     } else {
                    //         // console.log("7");
                    //         window.open("https://demo4-uat.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                    //     }
                    // } else {
                    //     if (url.indexOf("uat") != -1 || url.indexOf("qa") != -1) {
                    //         // console.log("8");
                    //         window.open("https://fidelity-hsacalculator-uat.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                        
                    //     } else {
                    //         // console.log("9");
                    //         window.open("https://fidelity-hsacalculator.ssnc.cloud/report/pdf/" + filename + ".pdf", '_blank');
                    //     }
                    // }
                }
                // console.log("url: " + url + ", filename: " + filename);
                this.pdfModalClose();
            }
        );

    }







    /**
     * Hide the visible modal.
     *
     * @memberOf NavigationComponent
     */
    closeModal() {
        this.modal.hide();
    }

    print() {
        window.print();
        // const head = document.getElementsByTagName('head')[0].innerHTML;
        // const header = document.getElementsByClassName('page-header')[0].innerHTML;
        // const results = document.getElementById('results').innerHTML;
        // const html = head + header + results;
        // const newWindow = window.open('', 'PrintWindow',
        //     'width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes');
        // newWindow.document.writeln(html);
        // newWindow.document.close();
        // newWindow.focus();
        // newWindow.print();
    }




}
