import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgIdleModule} from '@ng-idle/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { PagesModule } from './pages/pages.module';
import { SessionService } from './shared/session/session.service';
import { AnalyticsService } from './shared/analytics/analytics.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReportDataService } from './shared/models/reportdata.service';
import { AccordionModule } from 'ngx-bootstrap/accordion';
// import { CurrencyMaskModule } from "ng2-currency-mask";
// import { NgxCurrencyModule } from "ngx-currency";

import { IConfig, NgxMaskDirective, provideNgxMask, provideEnvironmentNgxMask } from 'ngx-mask'

const maskConfig: Partial<IConfig> = {
    validation: false,
  };
const maskConfigFunction: () => Partial<IConfig> = () => {
    return {
        validation: false,
    };
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        PagesModule,
        ReactiveFormsModule,
        NgIdleModule.forRoot(),
        BrowserAnimationsModule,
        NgxMaskDirective,
        AccordionModule.forRoot()
    ],
    providers: [
        SessionService, 
        ReportDataService,
        provideEnvironmentNgxMask(),provideNgxMask(),
        AnalyticsService],
    bootstrap: [AppComponent]
})
export class AppModule { }
