export const OOP_CONTENT = {
    A100100: [
        {
            image: '',
            text: '&#10003;&nbsp; You are generally in good health'
        },
        {
            image: '', //'icon-usage-care.png',
            text: '&#10003;&nbsp;One preventive visit at your doctor office'
        },
        {
            image: '', //'icon-usage-preventive.png',
            text: '&#10003;&nbsp; One visit, if any, with your Primary Care Physician'
        },
        {
            image: '', //'icon-usage-prescription.png',
            text: '&#10003;&nbsp; One or more prescriptions for common ailments'
        }
    ],
    A100200: [
        {
            image: '',
            text: '&#10003;&nbsp; You are in moderate good health with some minor ongoing conditions'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; One preventive visit at your doctor office'
        },
        {
            image: '', //'icon-usage-preventive.png',
            text: '&#10003;&nbsp; A few sick visits with related prescriptions'
        },
        {
            image: '', //'icon-usage-prescription.png',
            text: '&#10003;&nbsp; Up to five prescriptions, where some of the prescriptions may be used for chronic conditions'
        }
        // ,
        // {
        //     image: '', //'icon-usage-hospitalization.png',
        //     text: '&#10003;&nbsp; You may also have surgery that does not require overnight hospitalization'
        // }
    ],
    A100300: [
        {
            image: '',
            text: '&#10003;&nbsp; You have or may have significant medical needs or chronic conditions or you may be having a baby in the near future'
        },
        {
            image: '', //'icon-usage-care.png',
            text: '&#10003;&nbsp; One preventive visit at your doctor office'
        },
        {
            image: '', //'icon-usage-preventive.png',
            text: '&#10003;&nbsp; Multiple repeat visits with a Primary Care Physician or specialist'
        },
        {
            image: '', //'icon-usage-prescription.png',
            text: '&#10003;&nbsp; Up to ten or more prescriptions, where most of the prescriptions are used to manage chronic condition(s)'
        },
        {
            image: '', //'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; Major surgery that requires hospitalization'
        }
    ],
    B100100: [
        {
            image: '',
            text: '&#10003;&nbsp; You and your family are generally in good health'
        },
        {
            image: '', //'icon-usage-care.png',
            text: '&#10003;&nbsp; Family members typically each have a preventive visit at their doctor\'s office each year'
        },
        {
            image: '', //'icon-usage-preventive.png',
            text: '&#10003;&nbsp; Family members may also visit their Primary Care Physician each year'
        },
        {
            image: '', //'icon-usage-preventive.png',
            text: '&#10003;&nbsp; Family members fill up to fourteen prescriptions, where some of the prescriptions may be used for chronic conditions'
        },


        {
            image: '', //'icon-usage-prescription.png',
            text: '&#10003;&nbsp; In addition, family members may fill one or more prescriptions for common ailments'
        }
    ],
    B100200: [
        {
            image: '',
            text: '&#10003;&nbsp; You and your family members are in moderately good health with some minor ongoing conditions'
        },
        {
            image: '', //'icon-usage-care.png',
            text: '&#10003;&nbsp; Family members typically each have a preventive visit at their doctor office each year'
        },
        {
            image: '', //'icon-usage-preventive.png',
            text: '&#10003;&nbsp; Family members may also visit their Primary Care Physician or see a specialist a few times a year'
        },
        {
            image: '', //'icon-usage-prescription.png',
            text: '&#10003;&nbsp; IFamily members fill up to fourteen prescriptions, where some of the prescriptions may be used for chronic conditions'
        },
        {
            image: '', //'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; One or more family members may have surgery that does not require overnight hospitalization'
        }
    ],
    B100300: [
        {
            image: '',
            text: '&#10003;&nbsp; You or a family member may have significant medical needs or chronic conditions or you may be having a baby in the near future'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; Family members typically each have a preventive visit at their doctor office each year'
        },
        {
            image: '', // 'icon-usage-preventive.png',
            text: '&#10003;&nbsp; Family members may also visit their Primary Care Physician or see a specialist  multiple times a year'
        },
        {
            image: '', // 'icon-usage-prescription.png',
            text: '&#10003;&nbsp; Family members may fill up to twenty seven or more prescriptions, where most of the prescriptions are used to manage chronic condition(s)'
        },
        {
            image: '', // 'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; At least one of your family members may require outpatient surgery'
        },
        {
            image: '', // 'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; You or family member(s) may also have major surgery requiring hospitalization'
        }
    ],
    C100100: [
        {
            image: '',
            text: '&#10003;&nbsp; You are generally in good health'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; One preventive visit at your doctor office'
        },
        {
            image: '', // 'icon-usage-preventive.png',
            text: '&#10003;&nbsp; One visit, if any, with your Primary Care Physician'
        },
        {
            image: '', // 'icon-usage-prescription.png',
            text: '&#10003;&nbsp; One or more prescriptions for common ailments'
        }
    ],
    C100200: [
        {
            image: '',
            text: '&#10003;&nbsp; You are in moderate good health with some minor ongoing conditions'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; One preventive visit at your doctor office'
        },
        {
            image: '', // 'icon-usage-preventive.png',
            text: '&#10003;&nbsp; A few sick visits with related prescriptions'
        },
        {
            image: '', // 'icon-usage-prescription.png',
            text: '&#10003;&nbsp; Up to six prescriptions, where some of the prescriptions may be used for chronic conditions'
        },
        {
            image: '', // 'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; You may also have surgery that does not require overnight hospitalization'
        }
    ],
    C100300: [
        {
            image: '',
            text: '&#10003;&nbsp; You have or may have significant medical needs or chronic conditions or you may be having a baby in the near future'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; One preventive visit at your doctor office'
        },
        {
            image: '', // 'icon-usage-preventive.png',
            text: '&#10003;&nbsp; Multiple repeat visits with a Primary Care Physician or specialist'
        },
        {
            image: '', // 'icon-usage-prescription.png',
            text: '&#10003;&nbsp; Up to ten or more prescriptions, where most of the prescriptions are used to manage chronic condition(s)'
        },
        {
            image: '', // 'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; Major surgery that requiring hospitalization'
        }
    ],
    D100100: [
        {
            image: '',
            text: '&#10003;&nbsp; You and your family are generally in good health'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; Family members typically each have a preventive visit at their doctor office each year'
        },
        {
            image: '', // 'icon-usage-preventive.png',
            text: '&#10003;&nbsp; Family members may also visit their Primary Care Physician each year'
        },
        {
            image: '', // 'icon-usage-prescription.png',
            text: '&#10003;&nbsp; In addition, family members may fill one or more prescriptions for common ailments'
        }
    ],
    D100200: [
        {
            image: '',
            text: '&#10003;&nbsp; You and your family members are in moderately good health with some minor ongoing conditions'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; Family members typically each have a preventive visit at their doctor office each year'
        },
        {
            image: '', // 'icon-usage-preventive.png',
            text: '&#10003;&nbsp; Family members may also visit their Primary Care Physician or see a specialist  few times a year'
        },
        {
            image: '', // 'icon-usage-prescription.png',
            text: '&#10003;&nbsp; Family members fill up to sixteen prescriptions, where some of the prescriptions may be used for chronic conditions'
        },
        {
            image: '', // 'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; Family members may also have surgery that does not require overnight hospitalization'
        }
    ],
    D100300: [
        {
            image: '',
            text: '&#10003;&nbsp; You or a family member may have significant medical needs or chronic conditions or you may be having a baby in the near future'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; Family members typically each have a preventive visit at their doctor office each year'
        },
        {
            image: '', // 'icon-usage-preventive.png',
            text: '&#10003;&nbsp; Family members may also visit their Primary Care Physician or see a specialist  multiple times a year'
        },
        {
            image: '', // 'icon-usage-prescription.png',
            text: '&#10003;&nbsp; Family members may fill up to twenty seven or more prescriptions, where most of the prescriptions are used to manage chronic condition(s)'
        },
        {
            image: '', // 'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; At least one of your family members may require outpatient surgery'
        },
        {
            image: '', // 'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; You or family member(s) may also have major surgery requiring hospitalization'
        }
    ],
    E100100: [
        {
            image: '',
            text: '&#10003;&nbsp; You are generally in good health'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; One preventive visit at your doctor office'
        },
        {
            image: '', // 'icon-usage-preventive.png',
            text: '&#10003;&nbsp; One visit, if any, with your Primary Care Physician'
        },
        {
            image: '', // 'icon-usage-prescription.png',
            text: '&#10003;&nbsp; One or more prescriptions for common ailments'
        }
    ],
    E100200: [
        {
            image: '',
            text: '&#10003;&nbsp; You are in moderate good health with some minor ongoing conditions'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; One preventive visit at your doctor office'
        },
        {
            image: '', // 'icon-usage-preventive.png',
            text: '&#10003;&nbsp; A few sick visits with related prescriptions'
        },
        {
            image: '', // 'icon-usage-prescription.png',
            text: '&#10003;&nbsp; Up to six prescriptions, where some of the prescriptions may be used for chronic conditions'
        },
        {
            image: '', // 'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; You may also have surgery that does not require overnight hospitalization'
        }
    ],
    E100300: [
        {
            image: '',
            text: '&#10003;&nbsp; You have or may have significant medical needs or chronic conditions or you may be having a baby in the near future'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; One preventive visit at your doctor office'
        },
        {
            image: '', // 'icon-usage-preventive.png',
            text: '&#10003;&nbsp; Multiple repeat visits with a Primary Care Physician or specialist'
        },
        {
            image: '', // 'icon-usage-prescription.png',
            text: '&#10003;&nbsp; Up to ten or more prescriptions, where most of the prescriptions are used to manage chronic condition(s)'
        },
        {
            image: '', // 'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; You may also require outpatient surgery'
        },
        {
            image: '', // 'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; Major surgery that requires hospitalization'
        }
    ],
    F100100: [
        {
            image: '',
            text: '&#10003;&nbsp; You and your family are generally in good health'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; Family members typically each have a preventive visit at their doctor office each year'
        },
        {
            image: '', // 'icon-usage-preventive.png',
            text: '&#10003;&nbsp; Family members may also visit their Primary Care Physician each year'
        },
        {
            image: '', // 'icon-usage-prescription.png',
            text: '&#10003;&nbsp; In addition, family members may fill one or more prescriptions for common ailments'
        }
    ],
    F100200: [
        {
            image: '',
            text: '&#10003;&nbsp; You and your family members are in moderately good health with some minor ongoing conditions'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; Family members typically each have a preventive visit at their doctor office each year'
        },
        {
            image: '', // 'icon-usage-preventive.png',
            text: '&#10003;&nbsp; Family members may also visit their Primary Care Physician or see a specialist  few times a year'
        },
        {
            image: '', // 'icon-usage-prescription.png',
            text: '&#10003;&nbsp; Family members fill up to nineteen prescriptions, where some of the prescriptions may be used for chronic conditions'
        },
        { 
            image: '', // 'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; Family members may also have surgery that does not require overnight hospitalization'
        }
    ],
    F100300: [
        {
            image: '',
            text: '&#10003;&nbsp; You or a family member may have significant medical needs or chronic conditions or you may be having a baby in the near future'
        },
        {
            image: '', // 'icon-usage-care.png',
            text: '&#10003;&nbsp; Family members typically each have a preventive visit at their doctor office each year'
        },
        {
            image: '', // 'icon-usage-preventive.png',
            text: '&#10003;&nbsp; Family members may also visit their Primary Care Physician or see a specialist  multiple times a year'
        },
        {
            image: '', // 'icon-usage-prescription.png',
            text: '&#10003;&nbsp; Family members may fill up to twenty seven or more prescriptions, where most of the prescriptions are used to manage chronic condition(s)'
        },
        {
            image: '', // 'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; At least one of your family members may require outpatient surgery'
        },
        {
            image: '', // 'icon-usage-hospitalization.png',
            text: '&#10003;&nbsp; You or family member(s) may also have major surgery that requiring hospitalization'
        }
    ]
};
