<div style="margin-left: 0px;" class="row w-100 navigation-row mt-4 mb-3" >
    <div class="offset-3 offset-md-0 col-md-3 col-6" *ngIf="pageData.showNavigation && !pageData.firstPage">
        <div  class="nav-btn-container">
            <a style="font-weight: bold;" class="btn btn-prev btn-block btn-border" (click)="navPrev()" >
                Back
            </a>
        </div>
    </div>

     <div class="offset-3 offset-md-1 col-md-4 col-6"  *ngIf="!pageData.firstPage">
        <div  class="nav-btn-container">
            <a target="_blank" (click)="backToFdelity()" 
                style="font-weight: bold;" class="btn btn-fidelity btn-block btn-border">
                Back to Fidelity.com
            </a>
        </div>
    </div>

    <div class="offset-3 offset-md-1 col-md-3 col-6" *ngIf="!pageData.lastPage && !pageData.firstPage && !pageData.addPrint">
        <div  class="nav-btn-container">
            <a style="font-weight: bold;" class="btn btn-next btn-block btn-border" (click)="navNext()" >
                Continue
              </a>
        </div>
    </div>

    <div class="offset-3 offset-md-3 col-md-6 col-6" *ngIf="pageData.firstPage" >
        <div class="nav-btn-container">
            <a style="font-weight: bold;" class="btn btn-next btn-block btn-border" (click)="navNext()" >
            Continue
            </a>
        </div>
    </div>
    
    <div class="offset-3 offset-md-1 col-md-3 col-6" *ngIf="pageData.addPrint">
        <div  class="nav-btn-container">
            <a style="font-weight: bold;" class="btn btn-pdf btn-block btn-border" (click)="pdfPrint()()">
                Create PDF
            </a>
        </div>
    </div>


    
</div>

<div bsModal #errorModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Errors</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Please fix the following errors before proceeding:
                <ul>
                    <li *ngFor="let error of errorArray">{{error}}</li>
                </ul>
            </div>
        </div>
    </div>
</div>



<div bsModal #pdfModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Cover Page Information</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="pdfModalClose()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <h4>Client Information</h4>

                <div class="input-wrapper">
                    <div class="input-tag-narrow">First/Last Name</div>
                    <div class="input-box">
                        <input id="client_fname" class="S" type="text" size="30" value="">
                        <input id="client_lname" class="S" type="text" size="30" value="">
                    </div>
                    <div class="clear"></div>
                </div>



                <h4>Advisor Information</h4>

                <div class="input-wrapper">
                    <div class="input-tag-narrow">Full Name</div>
                    <div class="input-box">
                        <input id="advisor_name" class="S" type="text" size="30" value="">

                    </div>
                    <div class="clear"></div>
                </div>

                <div class="input-wrapper">
                    <div class="input-tag-narrow">Company</div>
                    <div class="input-box">
                        <input id="advisor_company" class="S" type="text" size="60" value="">
                    </div>
                    <div class="clear"></div>
                </div>

                <div class="input-wrapper">
                    <div class="input-tag-narrow">Phone number</div>
                    <div class="input-box">
                        <input id="advisor_phone" class="S" type="text" size="20" value="">
                        <div class="error_message spacer-wrapper-top" id="advisor_phone_error" style="display: none;">
                            Please provde a valid phone number
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>

                <div class="input-wrapper">
                    <div class="input-tag-narrow">E-mail address</div>
                    <div class="input-box">
                        <input id="advisor_email" class="S" type="text" size="30" value="">
                        <div class="error_message spacer-wrapper-top" id="advisor_email_error" style="display: none;">
                            Please provde a valid e-mail address
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>

                <div class="row ">
                    <div class="col-sm-offset-6  col-sm-6">
                        <!-- <div>
                          
                            <button type="button" class="btn btn-primary">View Report</button>
                        </div> -->

                        <div style="float: right;" class="nav-btn-container">
                            <a style="font-weight: bold;" class="btn btn-blue btn-block btn-border" (click)="pdfPrint()">
                                View Report
                            </a>
                        </div>

                    </div>

                </div>
                <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->

            </div>
        </div>
    </div>






    <div bsModal #pdfModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title pull-left">Errors</h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="pdfModalClose()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">


                    <p>
                        Please choose "allow pop-ups from this site" in your browser
                        to ensure the report is visible.
                    </p>

                    <h2>Client Information</h2>

                    <div class="input-wrapper">
                        <div class="input-tag-narrow">Full Name</div>
                        <div class="input-box">
                            <input id="client_name" type="text" size="20" value="">
                        </div>
                        <div class="clear"></div>
                    </div>



                    <h2>Advisor Information</h2>

                    <div class="input-wrapper">
                        <div class="input-tag-narrow">Full Name</div>
                        <div class="input-box">
                            <input id="advisor_name" type="text" size="20" value="">
                        </div>
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrapper">
                        <div class="input-tag-narrow">Company</div>
                        <div class="input-box">
                            <input id="advisor_company" type="text" size="20" value="">
                        </div>
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrapper">
                        <div class="input-tag-narrow">Address 1</div>
                        <div class="input-box">
                            <input id="advisor_address1" type="text" size="20" value=""><br />
                        </div>
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrapper">
                        <div class="input-tag-narrow">Address 2</div>
                        <div class="input-box">
                            <input id="advisor_address2" type="text" size="20" value="">
                        </div>
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrapper">
                        <div class="input-tag-narrow">City</div>
                        <div class="input-box">
                            <input id="advisor_city" type="text" size="20" value="">
                        </div>
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrapper">
                        <div class="input-tag-narrow">State</div>
                        <div class="input-box">
                            <select id="advisor_state" style="margin-top:8px; margin-bottom:8px;">
                                <option value=""></option>
                                <option value="AL">AL</option>
                                <option value="AK">AK</option>
                                <option value="AZ">AZ</option>
                                <option value="AR">AR</option>
                                <option value="CA">CA</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DE">DE</option>
                                <option value="DC">DC</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="HI">HI</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="IA">IA</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="MA">MA</option>
                                <option value="ME">ME</option>
                                <option value="MD">MD</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MS">MS</option>
                                <option value="MO">MO</option>
                                <option value="MT">MT</option>
                                <option value="NE">NE</option>
                                <option value="NV">NV</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NY">NY</option>
                                <option value="NM">NM</option>
                                <option value="ND">ND</option>
                                <option value="NC">NC</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="RI">RI</option>
                                <option value="SD">SD</option>
                                <option value="SC">SC</option>
                                <option value="TN">TN</option>
                                <option value="TX">TX</option>
                                <option value="UT">UT</option>
                                <option value="VT">VT</option>
                                <option value="VA">VA</option>
                                <option value="WA">WA</option>
                                <option value="WV">WV</option>
                                <option value="WI">WI</option>
                                <option value="WY">WY</option>
                            </select>
                        </div>
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrapper">
                        <div class="input-tag-narrow">Zip</div>
                        <div class="input-box">
                            <input id="advisor_zip" type="text" size="5" value="">
                            <div class="error_message spacer-wrapper-top" id="advisor_zip_error" style="display: none;">
                                Please provde a valid zip code
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrapper">
                        <div class="input-tag-narrow">Phone number</div>
                        <div class="input-box">
                            <input id="advisor_phone" type="text" size="20" value="">
                            <div class="error_message spacer-wrapper-top" id="advisor_phone_error" style="display: none;">
                                Please provde a valid phone number
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrapper">
                        <div class="input-tag-narrow">E-mail address</div>
                        <div class="input-box">
                            <input id="advisor_email" type="text" size="30" value="">
                            <div class="error_message spacer-wrapper-top" id="advisor_email_error" style="display: none;">
                                Please provde a valid e-mail address
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>

                    <div class="input-wrapper spacer-wrapper">
                        Email address* (optional) to receive a copy of report as a PDF attachment:<br />
                        <input id="attachment_email_custom" type="text" size="30" value="">
                        <div class="spacer-wrapper-top" id="processing_advisor" style="display: none;">Getting your report ...</div>
                        <div class="error_message spacer-wrapper-top" id="attachment_email_custom_error" style="display: none;">
                            Please provde a valid e-mail address
                        </div>
                        <br />
                        *Please note that your email address will not be captured or stored. It will only be used
                        in this instance to send you a copy of this report.
                    </div>

                    <div class="input-wrapper spacer-wrapper">
                        <div  class="nav-btn-container">
                            <a style="font-weight: bold;" class="btn btn-blue btn-block btn-border" (click)="navNext()" *ngIf="!pageData.lastPage && pageData.addPrint">
                                Continue
                                <!-- <img src="assets/img/icon-arrow-next.png" alt="icon-arrow-next" class="img-next"> -->
                            </a>
                        </div>
                    </div>
                    <!-- <div class="clear"></div> -->





                </div>
            </div>
        </div>
    </div>