import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

import { ReportData } from './reportdata.model';

@Injectable()
export class ReportDataService {

    reportData: ReportData = <ReportData>{};
    reportChange = new Subject<ReportData>();

    /**
     * Creates an instance of PageService.
     * @param {Title} titleService
     *
     * @memberOf PageService
     */
    constructor(private titleService: Title) { }

    /**
     * Sets page data for current page.
     *
     * @param {PageData} page
     *
     * @memberOf PageService
     */
    initReport(report: ReportData) {
        // set current page data
        // this.pageData = page;
        this.reportData.rptIntro1 = report.rptIntro1;
        this.reportData.rptIntro2 = report.rptIntro2;
        this.reportData.summaryFootnote = report.summaryFootnote;

        this.reportData.client_name = report.client_name;
        this.reportData.advisor_name = report.advisor_name;
        this.reportData.advisor_company = report.advisor_company;
        this.reportData.advisor_phone = report.advisor_phone;
        this.reportData.advisor_email = report.advisor_email;

        this.reportData.projRetBalance = report.projRetBalance;
        this.reportData.altRetBalance = report.altRetBalance;

        this.reportData.hsaContrib = report.hsaContrib;
        this.reportData.hsaOOP = report.hsaOOP;
        this.reportData.hsaSpendAlt = report.hsaSpendAlt;
        this.reportData.hsaContribAlt = report.hsaContribAlt;
        this.reportData.taxSavings = report.taxSavings;
        this.reportData.cumulativeTaxSavings = report.cumulativeTaxSavings;
        
        this.reportData.retirementAge = report.retirementAge;
        this.reportData.fedTax = report.fedTax;
        this.reportData.stateTax = report.stateTax;
        this.reportData.ror = report.ror;

    }

}
