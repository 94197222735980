<title>Step 1</title>
<div class="landing-container">
    <div class="row d-flex ">

        <div class="col-sm-6 left-green no-padding d-flex align-items-center">
            <div class="white-home-text">
                Plan for a brighter and
                healthier future with
                our health savings
                account calculator
            </div>
        </div>
       
        <div class="col-sm-6 no-padding">
            <img src="./assets/img/calculatorimage.png" alt="pointer" style="width: 100%" class="img-fluid">
        </div>
    </div>
</div>

<div (click)="closeTooltip();" class="row landing-intro1">
   
        <div class="col-md-5 col-sm-12">
            <div class="">
              
                <div class="landing-text">
                    <p>
                        The Health Savings Account (HSA) calculator can help you:
                    </p><p>
                        Easily estimate your health care expenses and savings potential.
                    </p><p>
                        Make informed decisions about healthcare planning, saving, and spending.
                    </p><p>
                        Learn valuable tips for maximizing the benefits of your health savings account.
                    </p>
              </div>
            </div>
        </div>

        <div class="col-md-7 col-sm-12 shiftUp">
            <div class="callout-container">
                <div id="step1" class="row padded-row">
                    <div class="col-sm-12">
                        <div class="green-title">About you</div>
                        <form novalidate [formGroup]="formStep1">
                            <div class="row">
                
                                <div class="col-sm-6 border-right">
                                    <label class="input-label" for="currentAge">Select the coverage that best fits your needs.</label>
                                    <select class="form-control" aria-label="Coverage type" formControlName="coverageType">
                                        <option aria-label="Individual Coverage" value="S"> Individual </option>
                                        <option aria-label="FamilyCoverage" value="F"> Family</option>
                                    </select>
                                    <app-error-message [errorMessage]="formErrors.coverageType"></app-error-message>
                                </div>
                
                                <div class="col-sm-6">
                                    <label class="input-label" for="currentAge">Do you currently have a health savings account (HSA)?</label>
                                    <div class="form-group">
                
                                        <select (ngModelChange)="togglePlanBalance($event)" class="form-control" 
                                            aria-label="Have an health savings account?" formControlName="currentHSAParticipant">
                                            <option aria-label="HSA Yes" value="Y"> Yes </option>
                                            <option aria-label="HSA No" value="N"> No</option>
                                        </select>
                                        <app-error-message [errorMessage]="formErrors.currentHSAParticipant"></app-error-message>
                                    </div>
                                </div>
                
                            </div>
                
                            <div class="row input-row">
                                <!-- <div class="col-sm-12 col-md-6 offset-sm-6 col-lg-5 col-lg-offset-5"> -->
                                    <div class="col-sm-6 border-right ">
                                        <div class="form-group">
                                            <div class="input-label">What is your current age?</div>
                                            <div class="form-group">
                                                <select class="form-control" formControlName="currentAge" 
                                                aria-label="Current age?" (ngModelChange)="currentAgeChange($event)">
                                                    <option *ngFor="let age of currentAgeOptions" [value]="age">
                                                        {{age}}
                                                    </option>
                                                </select>
                                                <app-error-message [errorMessage]="formErrors.currentAge"></app-error-message>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="showPlanBalance"  class="col-sm-6">
                                        <div class="form-group">
                                            <label class="input-label" for="currentAge">What is the HSA account balance?</label>

                                                <input 
                                                    aria-label="HSA account balance" 
                                                    formControlName="planBalance" 
                                                    type='text' prefix="$"
                                                    mask="separator" thousandSeparator="," 
                                                    separatorLimit="10000000"
                                                    class=" ">

                                                <app-error-message [errorMessage]="formErrors.planBalance"></app-error-message>
                                            
                                            <app-error-message [errorMessage]="formErrors.currentAge"></app-error-message>
                                        </div>
                                    </div>
                                    <div *ngIf="!showPlanBalance" class="col-sm-6">
                                        <div class="form-group">
                                            <div class="input-label">What tax brackets are you in?</div>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-6 fedPadding">
                                                    <div class="form-group">
                                                        <label style="white-space: nowrap;" for="fedTax">Federal <img triggers="click" placement="left" [outsideClick]="true" [popover]="fedTaxTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png"></label>
                                                        <select class="form-control taxdropdown" aria-label="Federal Tax" formControlName="fedTax">
                                                            <option *ngFor="let fed of fedTaxOptions" [value]="fed">
                                                                {{fed}}%
                                                            </option>
                                                        </select>
                                                        <app-error-message [errorMessage]="formErrors.fedTax"></app-error-message>
                                                    </div>
                                                </div>
                    
                                                <div class="col-sm-12 col-md-6  statePadding">
                                                    <div class="form-group">
                                                        <label for="stateTax">State </label><img triggers="click" placement="auto top" [outsideClick]="true" [popover]="stateTaxTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png">
                                                        <select aria-label="State Tax" class="form-control taxdropdown"  
                                                            (change)="setStateTax(stateTaxOptions[$event.target.selectedIndex])" >
                                                            <option *ngFor="let staterates of stateTaxOptions"
                                                                [value]="element"
                                                                [selected]="staterates.rate === stateTax && staterates.state === state" >
                                                                {{staterates.state}} - {{staterates.rate}}%
                                                            </option>
                                                        </select>
                                                        <app-error-message [errorMessage]="formErrors.stateTax"></app-error-message>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                <!-- </div> -->
                            </div>
                
                            <div class="row input-row">
                                <div class="col-sm-6 border-right"  [ngClass]="!showPlanBalance ? 'moveRetAgeUp' : '' " >
                                    <!-- <div class="form-group">

                                        <div class="input-label">What is your current age?</div>
                                        <div class="form-group">
                                            <select class="form-control" formControlName="currentAge" (ngModelChange)="currentAgeChange($event)">
                                                <option *ngFor="let age of currentAgeOptions" [value]="age">
                                                    {{age}}
                                                </option>
                                            </select>
                                            <app-error-message [errorMessage]="formErrors.currentAge"></app-error-message>
                                        </div>
                                    </div> -->
                                    <!-- <div class="col-sm-6 border-right"> -->
                                        <div class="form-group">
                                            <label class="input-label" for="currentAge">What is your estimated retirement age?</label>
                                            <select class="form-control" aria-label="Retirement age" formControlName="retirementAge">
                                                <option *ngFor="let age of retireAgeOptions" [value]="age">
                                                    {{age}}
                                                </option>
                                            </select>
                                            <app-error-message [errorMessage]="formErrors.retirementAge"></app-error-message>
                                        </div>
                                    <!-- </div> -->

                                </div>
                                
                                <div *ngIf="showPlanBalance" class="col-sm-6">
                                    <div class="form-group">
                                        <div class="input-label">What tax brackets are you in?</div>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-6 fedPadding">
                                                <div class="form-group">
                                                    <label style="white-space: nowrap;" for="fedTax">Federal <img triggers="click" placement="left" [outsideClick]="true" [popover]="fedTaxTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png"></label>
                                                    <select class="form-control taxdropdown" aria-label="Federal Tax" formControlName="fedTax">
                                                        <option *ngFor="let fed of fedTaxOptions" [value]="fed">
                                                            {{fed}}%
                                                        </option>
                                                    </select>
                                                    <app-error-message [errorMessage]="formErrors.fedTax"></app-error-message>
                                                </div>
                                            </div>
                
                                        <div class="col-sm-12 col-md-6  statePadding">
                                            <div class="form-group">
                                                <label for="stateTax">State </label><img triggers="click" placement="auto top" [outsideClick]="true" [popover]="stateTaxTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png">
                                                <select aria-label="State Tax" class="form-control taxdropdown"  
                                                    (change)="setStateTax(stateTaxOptions[$event.target.selectedIndex])" >
                                                    <option *ngFor="let staterates of stateTaxOptions"
                                                        [value]="element"
                                                        [selected]="staterates.rate === stateTax && staterates.state === state" >
                                                        {{staterates.state}} - {{staterates.rate}}%
                                                    </option>
                                                </select>
                                                <app-error-message [errorMessage]="formErrors.stateTax"></app-error-message>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                
                    
                                </div>
                            </div>
                
                            <div class="row input-row">
                                <!-- <div class="col-sm-6 border-right">
                                    <div class="form-group">
                                        <label class="input-label" for="currentAge">What is your retirement age?</label>
                                        <select class="form-control" formControlName="retirementAge">
                                            <option *ngFor="let age of retireAgeOptions" [value]="age">
                                                {{age}}
                                            </option>
                                        </select>
                                        <app-error-message [errorMessage]="formErrors.retirementAge"></app-error-message>
                                    </div>
                                </div> -->
                            
                                <div class="col-sm-12 mt-1">
                                        <!-- What is your investment risk tolerance? -->
                                <div style="margin-bottom: 15px;" class="input-label">What is the rate of return that best aligns with  your investment strategy?</div>
                                    <div id="slider-ror1" aria-label="Rate of Return" class="form-group">

                                        <span id="rorlabel" class="sr-only">Rate of Return Slider</span>
                                        <input id="ror"  formControlName="Investment rate of return" type="text">
                                    
                                        <div class="row slider-labels" >
                                                        
                                            <div class="col-sm-4 d-none d-sm-block  col-md-4 slider-text">
                                                <div class="text-left normal">Conservative<br>0-3%</div>
                                            </div>
                                            <div class="col-sm-4 d-none d-sm-block slider-text">
                                                <div class="text-center normal">Moderate<br>4-7%</div>
                                            </div>
                                            <div class="col-sm-4 d-none d-sm-block  col-md-4 slider-text">
                                                <div class="text-right normal">Aggressive<br>8-10%</div>
                                            </div>

                                            <div class="col-6 d-block d-sm-none slider-text">
                                                <div class="text-left normal">Conservative</div>
                                            </div>
                                            <div class="col-6 d-block d-sm-none slider-text">
                                                <div class="text-right normal">Aggressive</div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                </div>
                        </form>
                    </div>
                    <app-navigation></app-navigation>
                </div>
            </div>

    </div>
</div>
<div class="row landing-tips mt-5">

    <div class="col-sm-6"> 
        <span class="green-tip-hdr text-bold ">
            <img class="infoicon icon-piggy-bank" src="assets/img/piggy_bank.png" height="70" alt="icon-saver"> Triple tax advantages
        </span>
        <div class="tip-step1-left">
            
            <div class="green-tip-subhdr text-bold">Pretax contributions</div>
            <p class="tip-subhdr-text">
                All contributions to your HSA are in pre-tax dollars. That means you pay no taxes on the amount you save in the account.
            </p>
            <div class="green-tip-subhdr text-bold">Tax-free gains</div>
            <p class="tip-subhdr-text">
                As the account grows due to  investment returns and dividend reinvestments, you'll pay 
                no taxes on those gains. 
             </p>
            <div class="green-tip-subhdr text-bold">Tax-free withdrawals</div>
            <p class="tip-subhdr-text">
                An HSA has the additional advantage of being the only savings or
                investment account for which you pay no taxes on withdrawals
                used to pay for qualified medical expenses.
            </p>
        </div>
    </div>
    <div class="col-sm-6"> 
        <span class="green-tip-hdr text-bold"><img  src="assets/img/light_bulb.png" class="infoicon icon-light-bulb" height="70" alt="icon-saver">Did you know?</span>
        <div class="tip-step1-right">
            <p class="tip-subhdr-text">
               At age 55, you'll be eligible for catch-up contributions. This is an additional $1,000 above the annual contribution limit each year. This allows people nearing retirement age to maximize their tax savings while increasing their savings for health care in retirement.
            </p>
        </div>
    </div>
</div>


<ng-template #stateTaxTip>
    <span>
    Any HSA tax advantages referenced in this tool are with respect to federal taxation only. Contributions, investment 
    earnings, and distributions may or may not be subject to state taxation. The state tax rates are intended for 
    guidance purposes only and you should check with your tax advisor for more details.
    </span>
</ng-template>

<ng-template #fedTaxTip>
    <span>
        Your HSA contributions are not subject to federal income tax. Providing your federal tax rate will 
        help us estimate your potential annual tax savings. Need help estimating your tax 
        rate? <a target="_blank" href='https://www.fidelity.com/tax-information/tax-brackets'>https://www.fidelity.com/tax-information/tax-brackets</a>
    </span>
    <br/><br/>
    <span>
        Contributions, investment earnings, and distributions may or may not be subject to state taxation. State tax rates are intended for 
        guidance purposes only and you should check with your tax advisor for more details. 
    </span>
</ng-template>
