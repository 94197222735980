import { Injectable } from "@angular/core";
@Injectable()
export class PageData {
    currentPage: string;
    nextPage: string;
    prevPage: string;
    firstPage: boolean;
    lastPage: boolean;
    stepNum: number;
    progress: number;
    showProgress: boolean;
    stepTitle?: string;
    addPrint?: boolean;
    showDisclosures?: boolean;
    showFootnote?: boolean;
    showNavigation?: boolean;
    stepIcon: string;
}
